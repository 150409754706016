import React from 'react'
import { Db, Types } from 'brightsmith-core'
import { Utils } from 'brightsmith-ui'
import { setActiveFooterTab, setActiveSummaryTab } from '../store/actions'
import closeIcon from '../assets/close-icon.svg'

const { BrightsmithDb } = Db
const { ImageUtils, StyleUtils } = Utils

interface Props extends Types.BaseUIProps {
  baseSpecifications?: [string, string][]
  countryCode: string
  env: Types.Env
  options?: Types.GenericObject
  setActiveFooterTab: Function
  setActiveSummaryTab: Function
  totalPrice: Number
}

const name = 'Summary'

const Summary = ({ Frame, Image, Text, List, ListItem, styles }: Types.Components) => {
  return class extends React.PureComponent<Props> {
    containerRef: any

    constructor(props: Props) {
      super(props)
    }

    render() {
      const { after, baseSpecifications = {}, before, classes, countryCode, env, options = {}, setActiveFooterTab, setActiveSummaryTab, totalPrice } = this.props
      const firstColumn = ['Power', 'Performance', 'Dimensions', 'Steering & Suspension']
      const secondColumn = ['Body & Chassis', 'Standard Features']

      const hide = () => {
        setActiveFooterTab(0)
        setActiveSummaryTab(0)
      }
      const renderProperty = (property, attribute = '') => {
        return (
          <ListItem key={attribute} className={['Summary__list-item', classes?.listItem, classes?.listItemSpecifications]} style={{ ...styles?.listItem, ...styles?.listItemSpecifications }}>
            {attribute && (<Text className={['Summary__list-item--cateogry', classes?.listItemCategory, classes?.listItemSpecificationsCategory]} style={{ ...styles?.listItemCategory, ...styles?.listItemSpecificationsCategory }}>
              {attribute}:
            </Text>)}
            <Text className={['Summary__list-item--property', classes?.listItemProperty]} style={styles?.listItemProperty}>
              {property}
            </Text>
          </ListItem>
        )
      }

      return (
        <Frame className={['Summary__container', classes?.container]} scroll style={styles?.container}>
          {before}
          <Image className={['Summary__close', classes?.close]} src={ImageUtils.getAssetUrl(env, closeIcon)} onClick={hide} />
          <Frame className={['Summary__inner', classes?.inner]} style={styles?.inner}>
            <Frame className={['Summary__specifications', classes?.specifications]} style={styles?.specifications}>
              <Text className={['Summary__header', classes?.header]} style={styles?.header}>Base Vehicle</Text>
              <List className={['Summary__list', classes?.list, classes?.listSpecifications]} style={{ ...styles?.list, ... styles?.listSpecifications }}>
                {firstColumn.map(categoryName => {
                  return (
                    <>
                      <Text className={['Summary__section-name', classes?.sectionName]} style={styles?.sectionName}>
                        {categoryName}
                      </Text>
                      <Frame>
                        {(() => {
                          const category = baseSpecifications[categoryName] || {}
                          if (Array.isArray(category)) {
                            return category.map(property => renderProperty(property))
                          }
                          return Object.keys(category).map(attribute => {
                            const property = category[attribute]
                            return renderProperty(property, attribute)
                          })
                        })()}      
                      </Frame>
                    </>
                  )
                })}
              </List>
            </Frame>
            <Frame className={['Summary__specifications', classes?.specifications, classes?.secondCol]} style={{ ...styles?.specifications, ...styles?.secondCol }}>
              <List className={['Summary__list', classes?.list, classes?.listSpecifications]} style={{ ...styles?.list, ... styles?.listSpecifications }}>
                {secondColumn.map(categoryName => {
                  return (
                    <>
                      <Text className={['Summary__section-name', classes?.sectionName]} style={styles?.sectionName}>
                        {categoryName}
                      </Text>
                      <Frame>
                        {(() => {
                          const category = baseSpecifications[categoryName] || {}
                          if (Array.isArray(category)) {
                            return category.map(property => renderProperty(property))
                          }
                          return Object.keys(category).map(attribute => {
                            const property = category[attribute]
                            return renderProperty(property, attribute)
                          })
                        })()}      
                      </Frame>
                    </>
                  )
                })}
              </List>
            </Frame>
            <Frame className={['Summary__options', classes?.options]} style={styles?.options}>
              <Text className={['Summary__header', classes?.header]} style={styles?.header}>Build</Text>
              <List className={['Summary__list', classes?.list, classes?.listOptions]} style={{ ...styles?.list, ... styles?.listOptions }}>
                {Object.keys(options).map(category => {
                  const selections = options[category]
                  if (selections.length === 1) {
                    const property = selections[0].property
                    const prices = property?.price || {}
                    const price = prices[countryCode] || 0
                    return (
                      <ListItem key={property.id} className={['Summary__list-item', classes?.listItem, classes?.listItemOptions, classes?.row]} style={{ ...styles?.listItem, ...styles?.listItemOptions, ...styles?.row }}>
                        <Text className={['Summary__list-item--cateogry', classes?.listItemCategory]} style={styles?.listItemCategory}>
                          {category}: {property.name}
                        </Text>
                        {(!property.included && !!price && price > 0) && (
                          <Text className={['Summary__price', classes?.price]} style={styles?.price}>${price.toLocaleString()}</Text>
                        )}
                      </ListItem>
                    )
                  }

                  const categoryTotal = selections.reduce((total, selection) => {
                    const { property } = selection
                    const prices = property?.price || {}
                    const price = prices[countryCode] || 0
                    return total + price
                  }, 0)
                  return (
                    <ListItem key={category} className={['Summary__list-item', classes?.listItem, classes?.listItemOptions, classes?.row, classes?.listItemMultipleOptions]} style={{ ...styles?.listItem, ...styles?.listItemOptions, ...styles?.row, ...styles?.listItemMultipleOptions }}>
                      <Frame style={{ display: 'flex', flexDirection: 'row '}}>
                        <Text className={['Summary__list-item--cateogry', classes?.listItemCategory]} style={styles?.listItemCategory}>
                          {category}
                        </Text>
                        {(categoryTotal > 0) && (
                          <Text className={['Summary__price', classes?.price]} style={styles?.price}>${categoryTotal.toLocaleString()}</Text>
                        )}
                      </Frame>
                      {selections.map(selection => {
                        const { property } = selection
                        const price: Number = property.price[countryCode]
                        return (
                          <Frame className={['Summary__property-row', classes?.propertyRow]} style={styles?.propertyRow}>
                            <Text className={classes?.listItemProperty}>
                              {property.name}
                            </Text>
                            {(!!price && price > 0) && (
                              <Text className={['Summary__price', classes?.price]} style={styles?.price}>${price.toLocaleString()}</Text>
                            )}
                          </Frame>
                        )
                      })}
                    </ListItem>
                  )
                })}
              </List>
              <Frame className={['Summary__total-wrapper', classes?.totalWrapper]} style={styles?.totalWrapper}>
                <Text className={['Summary__total', classes?.total]} style={styles?.total}>Total</Text>
                <Text className={['Summary__price', classes?.price]} style={styles?.price}>${totalPrice.toLocaleString()}</Text>
              </Frame>
            </Frame>
          </Frame>
          {after}
        </Frame>
      )
    }
  }
}

const mapDispatchToProps = {
  setActiveFooterTab,
  setActiveSummaryTab
}

const mapStateToProps = (state, ownProps) => {
  const { api, app } = state
  
  const { countryCode, env } = app
  const db = new BrightsmithDb(api)
  const configuration = db.getConfiguration()
  const model = db.getCurrentModel()
  const totalPrice = db.calculateTotalPrice(configuration, model, countryCode)
  
  const { baseSpecifications } = model

  const options = {}
  if (configuration) {
    Object.keys(configuration).forEach(attributeId => {
      const propertyId = configuration[attributeId]
      const attribute: Db.AttributeEntry = db.getAttribute(attributeId)
  
      if (attribute.categoryId) {
        const category: Db.CategoryEntry = db.getCategory(attribute.categoryId)
        const property: Db.PropertyEntry = db.getProperty(propertyId)
        
        if (!options[category.name]) {
          options[category.name] = []
        }
        options[category.name].push({ attribute, property })
      }
    })
  }

  return {
    baseSpecifications,
    countryCode,
    env,
    options,
    totalPrice
  }
}

const style = theme => ({
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    width: '100%',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      paddingBottom: 60
    },
    ...theme?.Summary?.container,
  },
  close: {
    color: 'white',
    cursor: 'pointer',
    height: 25,
    position: 'absolute',
    right: 15,
    top: 15,
    width: 25
  },
  colorSquare: {
    ...StyleUtils.border('1px solid #cdcdcd'),
    height: 16,
    marginBottom: '8px !important',
    marginRight: 8,
    marginTop: 4,
    width: 16,
    ...theme?.Summary?.colorSquare
  },
  colorSquareGradient: {
    display: 'none',
    ...theme?.Summary?.colorSquareGradient
  },
  header: {
    color: theme?.colors?.secondary,
    fontSize: 18,
    fontWeight: '500',
    height: 32,
    marginBottom: '5px !important',
    padding: 5,
    paddingLeft: 0,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      paddingTop: 18
    },
    ...theme?.Summary?.header
  },
  inner: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    [`@media (max-width: ${theme.breakpoints.mobile}px)`]: {
      flex: 0,
      flexDirection: 'column',
      paddingTop: 24
    },
    ...theme?.Summary?.inner
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    ...theme?.Summary?.list
  },
  listItem: {
    fontSize: 14,
    lineHeight: '20px',
    listStyleType: 'none',
    ...theme?.Summary?.listItem
  },
  listItemCategory: {
    color: '#FFF',
    display: 'inline',
    flex: 1,
    fontWeight: 'bold',
    marginRight: 7,
    ...theme?.Summary?.listItemCategory
  },
  listItemSpecificationsCategory: {
    ...theme?.Summary?.listItemSpecificationsCategory
  },
  listItemProperty: {
    color: '#FFF',
    display: 'inline',
    flex: 1,
    ...theme?.Summary?.listItemProperty
  },
  listItemOptions: {
    marginBottom: 10,
    ...theme?.Summary?.listItemOptions
  },
  listItemMultipleOptions: {
    flexDirection: 'column !important',
    ...theme?.Summary?.listItemMultipleOptions
  },
  listItemSpecifications: {
    ...theme?.Summary?.listItemSpecifications
  },
  listOptions: {
    marginLeft: '0px !important',
    ...theme?.Summary?.listOptions
  },
  listSpecifications: {
    marginLeft: '0px !important',
    paddingRight: 20,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      paddingRight: 0
    },
    ...theme?.Summary?.listSpecifications
  },
  options: {
    flex: 1,
    ...theme?.Summary?.options
  },
  price: {
    alignSelf: 'flex-end',
    display: 'flex',
    color: '#fff',
    fontWeight: 'bold'
  },
  propertyRow: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    paddingTop: 8,
    ...theme?.Summary?.propertyRow
  },
  row: {
    ...StyleUtils.borderBottom('1px solid #fff'),
    display: 'flex',
    flexDirection: 'row',
    fontSize: 16,
    paddingBottom: 8,
    ...theme?.Summary?.row
  },
  secondCol: {
    marginTop: '46.5px !important',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      marginTop: '1px !important',
    },
  },
  sectionName: {
    ...StyleUtils.borderBottom('1px solid #fff'),
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '30px',
    marginBottom: '5px !important',
    textTransform: 'uppercase'
  },
  specifications: {
    flex: 1,
    ...theme?.Summary?.specifications
  },
  total: {
    alignSelf: 'flex-start',
    display: 'flex',
    color: '#fff',
    flex: 1,
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  totalWrapper: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 20,
    paddingTop: 18
  }
})

export { Summary, name, mapDispatchToProps, mapStateToProps, style }
