import { Types } from 'brightsmith-core'
import { CUSTOM } from './constants'

interface CustomState {
  activeFooterTab: number
  activeSummaryTab: number
  selectedDealer: Types.GenericObject,
  seatingFilterValue: any
}

const initialState: CustomState = {
  activeFooterTab: 0,
  activeSummaryTab: 0,
  selectedDealer: {},
  seatingFilterValue: null
}

const starEVReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOM.SELECT_DEALER: {
      const { selectedDealer } = action
      return Object.assign({}, state, {
        selectedDealer
      })
    }
    case CUSTOM.SET_ACTIVE_FOOTER_TAB: {
      const { tab } = action
      return Object.assign({}, state, {
        activeFooterTab: tab
      })
    }
    case CUSTOM.SET_ACTIVE_SUMMARY_TAB: {
      const { tab } = action
      return Object.assign({}, state, {
        activeSummaryTab: tab
      })
    }
    case CUSTOM.SET_SEATING_FILTER: {
      const { seatingFilterValue } = action
      return Object.assign({}, state, {
        seatingFilterValue
      })
    }
    case CUSTOM.SET_MODEL_FILTER: {
      const { modelFilterValue } = action
      return Object.assign({}, state, {
        modelFilterValue
      })
    }
    default:
      return state
  }
}

export default starEVReducer