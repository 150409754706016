import React from 'react'
import { withRouter } from 'react-router-dom'
import { Db, Types } from 'brightsmith-core'
import { Actions } from 'brightsmith-redux'
import * as blackCheck from '../assets/black-check.png'

const { BrightsmithDb } = Db


interface Props extends Types.BaseUIProps {
    classes: Types.Classes
    closeModal: Function
    env: Types.Env
    history: any
    modalData: Types.GenericObject
}

const name = 'StandardFeatures'

const StandardFeatures = ({ Frame, Text, List, ListItem, Image, styles }: Types.Components) => {
    return class extends React.Component<Props> {
        constructor(props: Props) {
            super(props)
            this.cancel = this.cancel.bind(this)
        }

        cancel() {
            const { closeModal } = this.props
            closeModal()
        }

        render() {
            const { after, before, classes, env } = this.props
            const firstColumn = [
                'Standard Roof',
                'LCD Screen',
                'USB Port and 12-Volt Power Outlet',
                'Seatbelts',
                'LED Exterior Lighting'
            ]
            const secondColumn = [
                '48-Volt Onboard Charger',
                'High Performance AC Motor',
                'Rear Drum Brakes',
                'Self-Cancelling Turn Signals',
                'Cooler'
            ]

            const check = <Image className={classes?.check} src={blackCheck} style={styles?.check} />

            return (
                <Frame className={['Modal__content', classes?.content]} style={styles?.content}>
                    {before}
                    <Frame className={['Modal__title-wrapper', classes?.titleWrapper]} style={styles?.titleWrapper}>
                        <Text className={['Modal__title', classes?.title]} style={styles?.title}>Standard Features</Text>
                        <Text className={['Modal__sub-title', classes?.subtitle]} style={styles?.subtitle}>Sirius golf carts and personal transportation vehicles (PTV) have more standard options than most other vehicles in its class.</Text>
                    </Frame>
                    <Frame className={['Modal__body', classes?.body]} style={styles?.body}>
                        <Frame className={['Modal__column', classes?.column]} style={styles?.column}>
                            <List className={classes?.list} style={styles?.list}>
                                {firstColumn.map(item => <ListItem className={classes?.listItem}>{check}{item}</ListItem>)}
                            </List>
                        </Frame>
                        <Frame className={['Modal__column', classes?.column]} style={styles?.column}>
                            <List className={classes?.list} style={styles?.list}>
                                {secondColumn.map(item => <ListItem className={classes?.listItem}>{check}{item}</ListItem>)}
                            </List>
                        </Frame>
                    </Frame>
                    {after}
                </Frame>
            )
        }
    }
}

const mapDispatchToProps: Types.GenericObject = {
    closeModal: Actions.Ui.closeModal,
}

const mapStateToProps = (state, ownProps) => {
    const { api, app, ui } = state
    const { env } = app
    const { modalData } = ui

    const db = new BrightsmithDb(api)
    const model = db.getCurrentModel()

    return {
        env,
        modalData,
        model
    }
}

const style = theme => ({
    body: {
        display: 'flex',
        flexDirection: 'row'
    },
    cancel: {
        backgroundColor: '#A3A2A2',
        border: 0,
        boxSizing: 'border-box',
        color: '#000',
        cursor: 'pointer',
        fontSize: 25,
        fontWeight: 'bold',
        height: 60,
        textTransform: 'uppercase',
        width: 256,
        ...theme?.ConflictModal?.cancel
    },
    check: {
        height: 22,
        marginRight: 8,
        marginTop: 3,
        width: 'auto'
    },
    column: {
        flex: 1
    },
    list: {
        fontSize: 24,
        fontWeight: 600,
        listStyle: 'none',
        marginLeft: '0px !important'
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        lineHeight: 1.2,
        marginBottom: 16
    },
    subtitle: {
        fontSize: 24,
        fontWeight: 300,
        lineHeight: 1.5,
        marginBottom: '25px !important',
        ...theme?.ConflictModal?.subTitle
    },
    title: {
        fontSize: 48,
        fontWeight: 'bold',
        marginBottom: '15px !important',
        [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
            fontSize: 24
        },
        ...theme?.ConflictModal?.title
    },
    titleWrapper: {
        ...theme?.ConflictModal?.titleWrapper
    }
})

export { StandardFeatures, mapDispatchToProps, mapStateToProps, name, style, withRouter }
