import React, { ReactNode } from 'react'
import { connect } from 'react-redux'
import { Actions } from 'brightsmith-redux'
import { Db, Types } from 'brightsmith-core'
import store from './store'
import RulesApi from './RulesApi'

const { BrightsmithDb } = Db

interface Props {
  actions: Types.GenericObject
  children?: ReactNode
  configuration?: Types.Configuration
  data?: Types.GenericObject
  endpoint?: string
  env?: Types.Env
  error?: any
  hasLoaded: boolean
  isLoading: boolean
  Loading?: ReactNode
  location?: Types.GenericObject
  params?: Types.GenericObject
}
let lastParams = ''

const name = 'ApiProvider'

const API = {
  CONFIGURATION: 'configuration',
  MODEL: 'model',
  MODELS: 'models',
}

class _ApiProvider extends React.Component<Props> {
  constructor(props: Props) {
    super(props)

    this.existingConfiguration = this.existingConfiguration.bind(this)
    this.paramsDidUpdate = this.paramsDidUpdate.bind(this)
    this.reset = this.reset.bind(this)
    this.run = this.run.bind(this)
  }

  paramsDidUpdate(params) {
    let didUpdate = false
    try {
      didUpdate = (JSON.stringify(params) !== lastParams)
      lastParams = JSON.stringify(params)
    } catch (e) {
      console.error('There was an error comparing params in ApiProvider', e);
    }
    return didUpdate
  }

  componentDidMount() {
    this.run()
  }

  componentDidUpdate(prevProps) {
    const { params } = this.props
    if (this.paramsDidUpdate(params)) {
      this.reset()
      this.run()
    }
  }

  existingConfiguration() {
    const { configuration } = this.props
    return configuration && Object.keys(configuration).length > 0
  }

  reset() {
    const { actions, configuration, endpoint, params } = this.props
    if (endpoint === API.MODEL) {
      if (this.existingConfiguration()) {
        store.dispatch(actions.Configuration.stash(configuration))
      }
      RulesApi.loadInitialConfiguration()
    }
  }

  run() {
    const { actions, data, endpoint, env, params } = this.props

    store.dispatch(actions.saveEnv(env))
    switch (endpoint) {
      case API.CONFIGURATION:
        store.dispatch(actions.Configuration.get(params?.configCode))
        break
      case API.MODEL:
        store.dispatch(actions.Model.fetch(params?.modelId, env, { data }))
        break
      case API.MODELS:
        store.dispatch(actions.Model.fetchAll(env))
        break
      default:
        console.warn(`Unrecognized endpoint '${endpoint}' passed to ApiProvider`)
    }
  }

  render() {
    const { children, isLoading, hasLoaded, Loading } = this.props
    let ready = (!isLoading && hasLoaded)

    return (
      <>
        {ready ? children : Loading}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { api } = state
  const { configuration, error, hasLoaded, isLoading } = api

  return {
    configuration,
    error,
    hasLoaded,
    isLoading
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: {
      Configuration: Actions.Configuration,
      Model: Actions.Model,
      saveEnv: Actions.saveEnv
    },
  }
}

const ApiProvider = connect(mapStateToProps, mapDispatchToProps)(_ApiProvider)

export { _ApiProvider, ApiProvider, API, name, mapStateToProps, mapDispatchToProps }
