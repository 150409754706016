import React from 'react'
import { bindActionCreators } from 'redux'
import { Db, Types } from 'brightsmith-core'
import { Actions } from 'brightsmith-redux'
import { Helpers, Utils } from 'brightsmith-ui'

const { BrightsmithDb } = Db
const { StyleUtils } = Utils
const Helper = Helpers.PropertyHelper

interface Opts {
  defaultThumbnail?: string
}

interface Props extends Types.BaseUIProps {
  actions: Types.ReduxActions
  attribute?: Db.AttributeEntry
  category: any  // TODO update with DbEntry
  classes: Types.Classes
  env: Types.Env
  isSelected: boolean
  modalData: Types.GenericObject
  model: Db.ModelEntry
  onClick?: Function
  opts?: Opts
  LSV: any  // TODO switch back to DbEntry
  select: Function
  type: string
}

const name = 'LSV'

const LSV = ({ Frame, Image, Text, Radio, Check, InfoButton, styles, AnalyticsApi }: Types.Components) => {
  return class extends React.PureComponent<Props> {
    constructor(props) {
      super(props)

      this.handleClick = this.handleClick.bind(this)
      this.openModal = this.openModal.bind(this)
      this.renderThumbnail = this.renderThumbnail.bind(this)
    }

    handleClick(LSV: any, type: string, isSelected: boolean, onClick?: Function) {
      const { category, select } = this.props
      const { active, attributeId, id, included } = LSV
      if (active === false || included || category.included) {
        return
      }

      const selection = { [attributeId]: id }
      if (isSelected) {
        AnalyticsApi.selectedProperty(selection)
        select(attributeId)
        if (onClick) {
          onClick(LSV)
        }
        return
      }
      
      AnalyticsApi.selectedProperty(selection)
      select(attributeId, id)
      if (onClick) {
        onClick(LSV)
      }
    }

    openModal(e) {
      e.stopPropagation()
      const { actions, modalData } = this.props
      actions.ui.openModal('info', modalData)
    }

    renderInput(LSV: Db.PropertyEntry, type: string, isSelected: boolean) {
      switch (type) {
        case 'radio':
          return (
            <Radio
              id={`LSV__input--radio--${LSV.id}`}
              className="LSV__input--radio"
              disabled={LSV.active === false}
              name={`LSV__attribute--${LSV.attributeId}`}
              isSelected={isSelected}
              style={styles?.radio}
            />
          )
        case 'check':
        default:
          return (
            <Check
              id={`LSV__input--checkbox--${LSV.id}`}
              className="LSV__input--checkbox"
              disabled={LSV.active === false}
              name={`LSV__attribute--${LSV.attributeId}`}
              isSelected={isSelected}
              style={styles?.radio}
            />
          )
      }
    }

    renderPrice(LSV: Db.PropertyEntry, classes: Types.Classes) {
      if (LSV.price && LSV.display?.hidePrice !== false) {
        if (LSV.price > 0) {
          return (
            <Text
              className={['LSV__tail-price', classes?.price]}
              style={styles?.price}>
              ${LSV.price.toLocaleString()}
            </Text>
          )
        }
      }
      return <></>
    }

    renderThumbnail(LSV: Db.PropertyEntry, env: Types.Env, model: Db.ModelEntry, classes: Types.Classes, opts?: Opts) {
      const thumbnail = Helper.getThumbnailUrl(env, model, LSV?.thumbnail) || opts?.defaultThumbnail

      if (thumbnail) {
        return (
          <Frame className={classes?.thumbnailWrapper} style={styles?.thumbnailWrapper} onClick={this.openModal}>
            <Image 
              className={classes?.thumbnail}
              src={thumbnail}
              style={styles?.thumbnail} />
          </Frame>
        )
      }
    }

    render() {
      const { after, before, classes, category, env, isSelected, model, onClick, opts, LSV, type } = this.props

      const style = { ...styles?.container, ...LSV?.style?.container }
      if (isSelected) {
        Object.assign(style, styles?.selected)
      }

      return (
        <Frame className={['LSV__container', classes?.container, { [classes?.pointer]: !LSV.incluced && !category.included }, {[classes?.selected]: isSelected}]} style={style}>
          {before}
          <Frame
            className={['LSV__inner', classes?.inner]}
            style={{ ...styles?.inner, ...LSV?.style?.inner }}
            key={LSV?.id}
            onClick={e => this.handleClick(LSV, type, isSelected, onClick)}>
            <Frame
              className={['LSV__inner-head', classes?.head]}
              style={{ ...styles?.head, ...LSV?.style?.head }}>
              {!category.included && !LSV.included && (
                this.renderInput(LSV, type, isSelected)
              )}
            </Frame>
            <Frame
              className={['LSV__inner-body', classes?.body]}
              style={{ ...styles?.body, ...LSV?.style?.body }}>
              {this.renderThumbnail(LSV, env, model, classes, opts)}
              <Frame
                className={['LSV__body-text-container', classes?.textWrapper]}
                style={{ ...styles?.textWrapper, ...LSV?.style?.textWrapper }}
                onClick={e => this.handleClick(LSV, type, isSelected)}>
                <Text
                  className={['LSV__body-text', classes?.bodyText]}
                  style={{ ...styles?.bodyText, ...LSV?.style?.bodyText }}>
                  {LSV.name}
                </Text>
                {(LSV.description || LSV.thumbnail) && (
                  <InfoButton
                    onClick={this.openModal} />
                )}
                {LSV.display?.subtitle && (
                  <Text
                    className={['LSV__body-subtitle', classes?.subtitle]}
                    style={{ ...styles?.subtitle, ...LSV?.style?.subtitle }}>
                    {LSV.display.subtitle}
                  </Text>
                )}
              </Frame>
            </Frame>
            <Frame
              className={['LSV__inner-tail', classes?.tail]}
              style={{ ...styles?.tail, ...LSV?.style?.tail }}>
              {this.renderPrice(LSV, classes)}
            </Frame>
          </Frame>
          {after}
          <Text 
            className={['LSV__disclaimer', classes?.disclaimer]}
            onClick={e => this.handleClick(LSV, type, isSelected, onClick)}
            style={styles?.disclaimer}>
              Pricing and Equipment may vary between state, check with your local dealer for details.
          </Text>
        </Frame>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { propertyId } = ownProps
  const { api, app } = state
  const { countryCode, env } = app
  const { configuration } = api

  const db = new BrightsmithDb(api)
  const model = db.getCurrentModel()
  const LSV = db.getProperty(propertyId, { countryCode })
  const attribute = db.getAttribute(LSV.attributeId)
  const category = db.getCategory(attribute.categoryId)

  const modalData = {
    property: LSV,
    image: LSV?.thumbnail,
    price: LSV?.price
  }

  const isSelected = configuration[LSV.attributeId] === propertyId

  return {
    category,
    countryCode,
    env,
    isSelected,
    modalData,
    model,
    LSV,
  }
}

const mapDispatchToProps: Types.GenericObject = (dispatch, ownProps) => {
  return {
    actions: {
      configuration: {
        ...bindActionCreators(Actions.Configuration, dispatch),
      },
      ui: {
        openModal: bindActionCreators(Actions.Ui.openModal, dispatch),
      }
    },
  }
}

const style = theme => ({
  container: {
    marginBottom: 10,
    background: '#fff',
    color: '#000 !important',
    fontFamily: 'myriad-pro, Roboto, sans-serif !important',
    ...StyleUtils.border('1px solid #e3e3e3'),
    ...StyleUtils.padding('10px 10px 10x 5px'),
    ...theme?.LSV?.container
  },
  disclaimer: {
    fontSize: 12,
    marginLeft: 5,
    marginTop: 12,
    fontStyle: 'italic',
    lineHeight: '12px'
  },
  inner: {
    display: 'flex',
    flexDirection: 'row',
    ...theme?.LSV?.inner
  },
  head: {
    height: 0,
    width: 0,
    visibility: 'hidden',
    ...theme?.LSV?.head
  },
  body: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    ...theme?.LSV?.body
  },
  bodyText: {
    display: 'flex',
    cursor: 'pointer',
    marginLeft: 5,
    ...theme?.LSV?.bodyText
  },
  pointer: {
    cursor: 'pointer'
  },
  price: {
    fontSize: 12,
    textAlign: 'right',
    ...theme?.LSV?.price,
  },
  radio: {
    borderColor: theme?.colors?.secondary,
    '&:checked': {
      backgroundColor: theme?.colors?.primary,
    }
  },
  selected: {
    background: '#f8f7f7',
    ...StyleUtils.border('2px solid #1a3249'),
    marginBottom: 9,
    marginLeft: -1,
    marginRight: -1,
    marginTop: -1
  },
  subtitle: {
    color: theme?.colors?.secondary,
    fontSize: 11,
    fontStyle: 'italic',
    marginTop: 5,
  },
  tail: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    ...theme?.LSV?.tail
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    ...theme?.LSV?.textWrapper
  },
  thumbnail: {
    display: 'flex',
    height: 30,
    width: 'auto',
    ...theme?.LSV?.thumbnail
  },
  thumbnailWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: 7,
    minHeight: 30,
    minWidth: 50,
    overflow: 'hidden',
    ...theme?.LSV?.thumbnailWrapper
  }
})

export { LSV, mapDispatchToProps, mapStateToProps, name, style }
