import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'brightsmith-core'
import 'pretty-checkbox/dist/pretty-checkbox.min.css'
import 'react-toastify/dist/ReactToastify.css'

import { theme } from './components'
import store from './shared/store/'
import WebRouter from './WebRouter'
import i18n from './shared/i18n/'

declare global {
  interface Window {
    Userback: any
  }
}

const container = 'App--themed'
const css = `
  .${container} {
    background-color: #fff;
    height: 100%;
  }
  .${container} input[type=text]::placeholder {
    text-transform: initial;
  }
  .${container} .pretty input + .state label:before {
    border-color: ${theme?.defaults?.Radio?.borderColor ||
      theme?.colors?.secondary} !important;
    border-width: ${theme?.defaults?.Radio?.borderWidth || 1}px;
  }
  .${container} .pretty input[disabled]~* {
    opacity: 1;
  }
  .${container} .pretty input[disabled]~* label:before {
    background-color: ${
      theme?.defaults?.Radio?.disabled?.backgroundColor
    } !important;
    border-color: ${theme?.defaults?.Radio?.disabled?.borderColor} !important;
  }
  .${container} .pretty input[type=checkbox] + .state label:before {
    border-radius: ${theme?.defaults?.Check?.borderRadius || '20%'};
    top: -1px;
  }
  .${container} .pretty input:checked + .state label:after {
    background-color: ${theme?.defaults?.Radio?.selectedColor ||
      theme?.colors.primary} !important;
    transform: scale(${theme?.defaults?.Radio?.scale || 0.9});
    top: -1px;
  }
  .${container} .pretty .state label {
    line-height: 1.25;
    max-width: 100%;
    padding-left: 1.5em;
    text-indent: 0;
    white-space: initial;
  }
  .${container} .pretty.p-icon .state .icon {
    top: -1px;
  }
  .${container} .react-select__control {
    border-color: ${theme?.defaults?.Select?.borderColor ||
      theme?.colors?.secondary};
    border-bottom-width: 2px;
    box-shadow: none;
  }
  .${container} .react-select__indicator-separator {
    display: none;
  }
  .${container} .react-select__menu {
    margin-top: 0;
  }
  .${container} .react-select__placeholder {
    color: ${theme?.select?.placeholder?.color || theme?.colors?.secondary};
    text-transform: ${theme?.select?.placeholder?.textTransform || 'uppercase'};
  }
  .${container} .react-select__single-value {
    overflow: visible;
  }
  .${container} .react-select__value-container {
    padding-left: 0;
    text-indent: -2px;
  }
  .${container} a:hover {
    transition: color 350ms;
    color: ${theme?.colors?.primary};
  }
  .${container} .Body--full-screen .Slideshow__container {
    bottom: 0;
    top: 0;
  }
  @media (min-width: 961px) {
    .${container} .Canvas__container {
      height: initial !important;
    }
  }
  .${container} .Leads-Form__container .react-select__control {
    background: none;
    border: 0;
    border-radius: 0;
  }
  .${container} .ColorSquare__tooltip:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 9.5px 0 9.5px;
    border-color: #000 transparent transparent transparent;
    position: absolute;
    left: 26px;
    bottom: -18px;
  }
  .${container} .EstimatePaymentModal__body label {
    font-weight: 500;
  }
  .${container} .EstimatePaymentModal__body label::before {
    top: 2px;
  }
  .${container} .EstimatePaymentModal__body svg {
    top: 2px !important;
  }
  .${container} .payment-option--selected label::before {
    background: green;
  }
  .${container} .payment-option--selected svg {
    color: #fff !important;
    padding: 4px !important;
  }
  .${container} .Slideshow__thumb:first-child .Slideshow__thumb-inner {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: #fff;
  }
  .vehicle-sirius-2-plus-2 img {
    transform: scale(1.15);
    margin: 9% 0;
  }
  .vehicle-sirius-2-plus-2 p {
    z-index: 2;
  }
  .vehicle-sirius-4-plus-2 img {
    margin: 3.2% 0;
  }
  .vehicle-sirius-4-plus-2-lifted img {
    transform: scale(0.95);
    margin-bottom: -7%;
  }
  .vehicle-sirius-4-plus-2-lifted p {
    z-index: 2;
  }
  @keyframes spin {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
  }

  body {
    background-color: #e3e3e3 !important;
  }
  .content {
    margin-bottom: 0 !important;
    padding: 0 !important;
  }
  .content .entry-header {
    display: none !important;
  }
  .content .entry-content ol, .content .entry-content p, .content .entry-content ul {
    margin-bottom: 0;
  }
  .entry.page {
    margin-bottom: 0 !important;
  }
  .site-container .site-inner {
    padding-top: 15px;
  }
  .site-container .site-inner .wrap {
    max-width: none;
  }
  @media (max-width: 860px) {
    .wrap {
      padding: 0 !important;
    }
  }
  .footer-widgets, .site-footer {
    display: none !important;
  }
  .site-inner {
    padding-top: 0 !important;
  }
  .container {
    width: auto !important;
  }
  p {
    margin-bottom: 0;
  }
`

class App extends React.PureComponent {
  componentDidMount() {
    if (process.env.BRIGHTSMITH_USERBACK === 'true') {
      window.Userback = window.Userback || {}
      window.Userback.access_token =
        '8607|45248|e2LS06Yjy8Bz0pohZV7NTGFcs13HFlgKf1YMC7TsjI6ZvsMSaS'
      ;(function(d) {
        var s = d.createElement('script')
        s.async = true
        s.src = 'https://static.userback.io/widget/v1.js'
        ;(d.head || d.body).appendChild(s)
      })(document)
    }
  }

  render() {
    return (
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <div className="App">
            <Helmet>
              <title>Star EV | Configurator</title>
              <link rel="canonical" href="http://starev.com/build" />
              <link
                rel="stylesheet"
                href="https://use.typekit.net/ynv3ylw.css"
              />
              <meta name="description" content="Star EV configurator" />
            </Helmet>
            <ThemeProvider.ThemeProvider
              containerClass={container}
              css={css}
              theme={theme}>
              <WebRouter />
            </ThemeProvider.ThemeProvider>
          </div>
        </I18nextProvider>
      </Provider>
    )
  }
}

export default App
