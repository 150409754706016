
import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import starEV from './reducer'
import { Reducers } from 'brightsmith-redux'

const store = createStore(combineReducers({ ...Reducers, starEV }), applyMiddleware(thunk))
console.log(store.getState())

export default store
