import React from 'react'
import { Types } from 'brightsmith-core'
import { Utils } from 'brightsmith-ui'
import { setActiveFooterTab } from '../store/actions'

const { StyleUtils } = Utils

interface Props extends Types.BaseUIProps {
  classes: Types.Classes
  leadData: any
  onCancel: Function
  onSubmit: Function
  setActiveFooterTab: Function
  submitDisabled?: boolean
  submitting: boolean
}

const name = 'SubmitButtons'

const SubmitButtons = ({ Frame, Button, Check, Text, Loading, styles }: Types.Components) => {
  return class extends React.Component<Props> {
    constructor(props: Props) {
      super(props)
    }

    render() {
      const { classes, leadData, onCancel, onSubmit, submitDisabled, setActiveFooterTab, submitting } = this.props
      const disclaimer = `
        By agreeing, I understand that I am providing express consent for StarEV affiliates 
        to contact me about this request or similar topics. I know that I can opt out from future
        commuication at any time.
      `
      const handleCancel = () => {
        setActiveFooterTab(0)
      }
      // const isDisabled = submitDisabled || submitting || !leadData['findADealer']
      

      return (
        <Frame className={['SubmitButtons__container', classes?.container]}>
          <Text className={['SubmitButtons__newsletter', classes?.newsletter]} style={styles?.newsletter}>
            Newsletter Signup
          </Text>
          <Check 
            className={['SubmitButtons__agree', classes?.agree]}
            label={'By agreeing, I understand that I am providing express consent for StarEV affiliates to send me an email newsletter'}
            style={styles?.check}
          />
          <Text className={['SubmitButtons__disclaimer', classes?.disclaimer]} style={styles?.disclaimer}>
            {disclaimer}
          </Text>
          <Text className={['SubmitButtons__terms', classes?.terms]} style={styles?.terms}>
            View Terms and Privacy Policy
          </Text>
          <Button
            className={['SubmitButtons__submit', classes?.submit, classes?.button]}
            onClick={(e) => {
              if (submitting) {
                return
              }
              onSubmit(e)
            }}
            style={{ ...styles?.submitButton, ...styles?.button }}>
              {submitting ? <Loading backgroundColor="#FFF" color="#AAA" height={40} width={40} /> : 'Submit'}
          </Button>
          <Button
            className={['SubmitButtons__cancel', classes?.cancel, classes?.button]}
            onClick={handleCancel}
            style={{ ...styles?.submitButton, ...styles?.button }}>
              Cancel
          </Button>
        </Frame>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { leads } = state
  return {
    leadData: leads
  }
}

const mapDispatchToProps = {
  setActiveFooterTab
}

const style = theme => ({
  agree: {
    fontSize: 10,
    marginBottom: '10px !important'
  },
  button: {
    background: '#000',
    borderWidth: 0,
    color: '#fff',
    cursor: 'pointer',
    flex: 1,
    fontSize: 21,
    fontWeight: 'bold',
    minHeight: 56,
    marginBottom: '10px !important',
    position: 'relative',
    textTransform: 'uppercase',
    '&:hover': {
      background: '#000'
    }
  },
  cancel: {
    backgroundColor: '#4B4B4B',
    marginBottom: 0
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    ...StyleUtils.margin('35px 5px 0'),
    width: '100%'
  },
  disabled: {
    // backgroundColor: '#222',
    // color: '#d5d5d5'
    opacity: .2
  },
  disclaimer: {
    fontSize: 10,
    lineHeight: 1.25,
    marginBottom: '10px !important'
  },
  newsletter: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: '10px !important',
    textTransform: 'uppercase'
  },
  terms: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: '20px !important',
    textDecoration: 'underline',
    textTransform: 'uppercase',
  }
})

export { SubmitButtons, mapDispatchToProps, mapStateToProps, name, style }
