import { Types } from 'brightsmith-core'
import { CUSTOM } from './constants'

const selectDealer = (selectedDealer: Types.GenericObject) => {
  return {
    type: CUSTOM.SELECT_DEALER,
    selectedDealer 
  }
}

const setActiveFooterTab = tab => {
  return {
    type: CUSTOM.SET_ACTIVE_FOOTER_TAB,
    tab
  }
}

const setActiveSummaryTab = tab => {
  return {
    type: CUSTOM.SET_ACTIVE_SUMMARY_TAB,
    tab
  }
}

const setSeatingFilter = seatingFilterValue => {
  return {
    type: CUSTOM.SET_SEATING_FILTER,
    seatingFilterValue
  }
}

const setModelFilter = modelFilterValue => {
  return {
    type: CUSTOM.SET_MODEL_FILTER,
    modelFilterValue
  }
}

export {
  selectDealer,
  setActiveFooterTab,
  setActiveSummaryTab,
  setSeatingFilter,
  setModelFilter
}