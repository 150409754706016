import React from 'react'
import axios from 'axios'
import { Types } from 'brightsmith-core'
import { Utils } from 'brightsmith-ui'

const { StyleUtils } = Utils

interface Props extends Types.BaseUIProps {
  classes: Types.Classes
  showLeadsForm: Function
}

const name = 'ConnectButtons'

const ConnectButtons = ({ Frame, Button, styles }: Types.Components) => {
  return class extends React.Component<Props> {
    constructor(props: Props) {
      super(props)
    }

    render() {
      const { classes, showLeadsForm } = this.props
      return (
        <Frame className={['ConnectButtons__container', classes?.container]}>
          <Button
            className={['ConnectButtons__connect', classes?.connect, classes?.button]}
            onClick={showLeadsForm}
            style={{ ...styles?.submitButton, ...styles?.button }}>
              Connect with a Dealer
          </Button>
        </Frame>
      )
    }
  }
}

const style = theme => ({
  button: {
    borderWidth: 0,
    color: '#fff',
    cursor: 'pointer',
    flex: 1,
    fontFamily: 'inherit',
    fontSize: 18,
    fontWeight: 'bold',
    minHeight: 48,
    textTransform: 'uppercase',
    '&:hover': {
      background: '#000'
    },
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      fontSize: 12
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    ...StyleUtils.margin('0 30px'),
    ...StyleUtils.padding('20px 0'),
  },
  connect: {
    background: '#000'
  }
})

export { ConnectButtons, name, style }
