import React from 'react'
import { withRouter } from 'react-router-dom'
import { Types } from 'brightsmith-core'
import { Actions } from 'brightsmith-redux'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

interface Literature {
  title: string
  url: string
}

interface Props extends Types.BaseUIProps {
  classes: Types.Classes
  closeModal: Function
  env: Types.Env
  literature: Literature[]
}

const name = 'DownloadBrochureModal'

const DownloadBrochureModal = ({ Frame, Text, Link, Icon }: Types.Components) => {
  return class extends React.Component<Props> {
    renderLiterature() {
      const { env, literature = [], classes } = this.props
      const { BRIGHTSMITH_S3_BUCKET_PATH } = env
      return literature.map(lit => (
        <Link
          className={['DownloadBrochureModal__link', classes?.link]}
          href={`${BRIGHTSMITH_S3_BUCKET_PATH}${lit.url}`}
          key={lit.url}
          target="_blank">
          <Icon icon={faFilePdf} />
          <Text>{lit.title}</Text>
        </Link>
      ))
    }

    render() {
      const { classes } = this.props
      return (
        <Frame className={['DownloadBrochureModal__container', classes?.container]}>
          <Text className={['DownloadBrochureModal__title', classes?.title]}>
            Download a brochure
          </Text>
          <Text className={['DownloadBrochureModal__description', classes?.description]}>
            Choose one of the brochures to download
          </Text>
          {this.renderLiterature()}
        </Frame>
      )
    }
  }
}

const mapDispatchToProps = {
  closeModal: Actions.Ui.closeModal
}

const mapStateToProps = (state, ownProps) => ({
  env: state.app.env,
  literature: state.ui.modalData
})

const style = theme => ({
  ...theme?.Modal,
  link: {
    display: 'flex',
    fontWeight: 'bold',
    gap: '10px',
    textDecoration: 'none',
    color: 'black',
    border: '1px solid #E3E3E3',
    padding: '1rem',
    '&:hover': {
      cursor: 'pointer',
    },
  }
})

export { DownloadBrochureModal, mapDispatchToProps, mapStateToProps, name, style, withRouter }
