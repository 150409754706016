import React from 'react'
import { withRouter } from 'react-router-dom'
import { Db, Types } from 'brightsmith-core'
import { Actions } from 'brightsmith-redux'

const { BrightsmithDb } = Db

interface Props extends Types.BaseUIProps {
  classes: Types.Classes
  closeModal: Function
  paymentOptions: any
  totalPrice: number
}

interface State {
  apr: number
  downPayment: number
  term: number
}

const name = 'EstimatePaymentModal'

const EstimatePaymentModal = ({ Frame, Text, Input, Radio, Link }: Types.Components) => {
  return class extends React.Component<Props, State> {
    constructor(props) {
      super(props)

      this.state = {
        apr: 0,
        downPayment: 0,
        term: 24
      }

      this.onChange = this.onChange.bind(this)
      this.renderPaymentOption = this.renderPaymentOption.bind(this)
    }

    onChange(e) {
      const { totalPrice } = this.props 
      if (e.target.value <= totalPrice) {
        this.setState({
          downPayment: e.target.value
        })
      }
    }

    renderPaymentOptions() {
      const { classes } = this.props
      const paymentOptions = [
        {
          title: '0% for 24 months',
          apr: 0,
          term: 24
        },
        {
          title: '1.99% for 36 months',
          apr: 0.0199,
          term: 36
        },
        {
          title: '2.99% for 48 months',
          apr: 0.0299,
          term: 48
        },
        {
          title: '4.99% for 60 months',
          apr: 0.0499,
          term: 60
        },
      ]

      return (
        <Frame className={['EstimatePaymentModal__payments-container', classes?.paymentsContainer]}>
          {paymentOptions.map(option => this.renderPaymentOption(option))}
        </Frame>
      )
    }

    renderPaymentOption({ apr, term, title }) {
      const { classes } = this.props
      const isSelected = (this.state.apr === apr)
      return (
        <Frame className={['EstimatePaymentModal__payment-option-container', classes?.paymentOptionContainer]}>
          <Radio
            className={{[`${classes?.selected} payment-option--selected`]: isSelected}}
            key={title}
            onChange={() => this.setState({ apr, term })}
            name="payment-option"
            isSelected={isSelected}
            label={title}
          />
        </Frame>
      )
    }

    renderDownPayment() {
      const { apr, downPayment, term } = this.state
      const { classes, totalPrice } = this.props

      const totalRemaining = totalPrice - downPayment
      const monthlyInterest = totalRemaining * apr / term
      const monthly = (totalRemaining / term) + monthlyInterest

      return (
        <Frame className={['EstimatePaymentModal__down-payment-container', classes?.downPaymentContainer]}>
          <Input
            className={['EstimatePaymentModal__down-payment-input', classes?.downPaymentInput]}
            onChange={this.onChange}
            placeholder="Enter down payment"
            type="number"
            value={downPayment !== 0 ? downPayment : null} />
          <Text>Estimated Monthly Payment</Text>
          <Text className={['EstimatePaymentModal__down-payment-result', classes?.downPaymentResult]}>
            ${monthly.toFixed(2)}
          </Text>
        </Frame>
      )
    }

    render() {
      const { classes } = this.props
      return (
        <Frame className={['EstimatePaymentModal__container', classes?.container]}>
          <Text className={['EstimatePaymentModal__title', classes?.title]}>
            Estimate your monthly payment
          </Text>
          <Text className={['EstimatePaymentModal__description', classes?.description]}>
            Please choose one of the financing options below offered by <Link className={['EstimatePaymentModal__link', classes?.link]} href="https://secure.sheffieldfinancial.com/newLoanApplication.aspx?m9a">Sheffield Financial</Link>
          </Text>
          <Frame className={['EstimatePaymentModal__body', classes?.body]}>
            <Frame className={['EstimatePaymentModal__body__top', classes?.bodyTop]}>
              {this.renderPaymentOptions()}
              {this.renderDownPayment()}
            </Frame>
            <Text className={['EstimatePaymentModal__fine-print', classes?.finePrint]}>
              *Applies to Installment Loans Only<br/>
              New equipment only. Available to qualified buyers with rates dependent on credit records. Not all customers will qualify for the above listed programs. Higher rates may apply for buyers with marginal credit. "No down payment" offers do not apply to buyers with marginal credit. Commercial Only refers to a plan that is only available for an applicant using the equipment for Commercial or Business Use.
              NOTE: ALL INFORMATION SUBJECT TO CHANGE. All programs and specials are subject to dealer participation; contact your local dealer. Customer documentation fee may apply on any of the above financing programs. All loans subject to credit approval. Ask your local dealer for details.
            </Text>
          </Frame>
        </Frame>
      )
    }
  }
}

const mapDispatchToProps = {
  closeModal: Actions.Ui.closeModal
}

const mapStateToProps = (state, ownProps) => {
  const { api, app, ui } = state
  const { configuration } = api
  const { countryCode } = app
  const { modalData } = ui
  const paymentOptions = modalData?.paymentOptions

  const db = new BrightsmithDb(api)
  const model = db.getCurrentModel()
  const totalPrice = db.calculateTotalPrice(configuration, model, countryCode)

  return {
    paymentOptions,
    totalPrice
  }
}

const style = theme => ({
  ...theme?.Modal,
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.base.fontSize}px`,
  },
  bodyTop: {
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      flexDirection: 'column'
    }
  },
  paymentsContainer: {
    color: '#A3A2A2',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    fontSize: 28,
    gap: `${theme.base.fontSize}px`,
    marginBottom: 23,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      fontSize: 19
    }
  },
  downPaymentContainer: {
    backgroundColor: '#E3E3E3',
    flex: 1,
    fontSize: 28,
    fontWeight: 100,
    padding: theme.base.fontSize,
    borderBottom: '5px solid black',
    textColor: '#4B4B4B',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      fontSize: 19
    }
  },
  finePrint: {
    fontSize: '8px',
    lineHeight: 1
  },
  link: {
    color: 'green'
  },
  paymentOptionContainer: {
    display: 'flex',
    flex: 1,
    fontWeight: 'bold'
  },
  selected: {
    color: '#000'
  },
  downPaymentInput: {
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: theme.base.fontSize,
    fontFamily: 'inherit',
    fontSize: 28,
    height: 80,
    background: '#fff',
    border: 0,
    paddingLeft: 20,
    fontWeight: 100,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      fontSize: 19
    }
  },
  downPaymentResult: {
    color: '#4B4B4B',
    fontSize: 45,
    fontWeight: 'bold',
    lineHeight: 1
  }
})

export { EstimatePaymentModal, mapDispatchToProps, mapStateToProps, name, style, withRouter }
