import React from 'react'
import { withRouter } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { AnalyticsProvider } from 'brightsmith-analytics'
import { Db } from 'brightsmith-core'
import { Types } from 'brightsmith-core'
import { Actions } from 'brightsmith-redux'
import { Utils } from 'brightsmith-ui'
import { ApiProvider, API } from '../ApiProvider'

const { BrightsmithDb } = Db
const { DataUtils } = Utils

interface Model extends Db.ModelEntry {
  category: string
}

interface Props {
  actions: Types.GenericObject
  classes?: Types.Classes
  data: Model[]
  env: Types.Env,
  history: any
  match: Types.GenericObject
  openModal: Function
  session: Types.Session
  setConfigCode: Function
  setConfiguration: Function
  stash: Function
}

interface State {
  models: Model[]
}

const name = 'Build'

const Build = ({ Frame, Body, Loading, Modal, ModalBackground, VehicleRow, VehicleSelectModal, BuildCodeButton, AboutSirius, LoadBuildModal, StandardFeatures, styles }: Types.Components) => {
  return class extends React.PureComponent<Props, State> {
    constructor(props) {
      super(props)

      this.clearConfigurations = this.clearConfigurations.bind(this)
      this.selectVehicle = this.selectVehicle.bind(this)
    }

    componentDidMount() {
      const { history } = this.props
      const queryParams = new URLSearchParams(window.location.search)
      if (queryParams.has('e')) {
        toast.error('Configuration does not exist')
      }
      if (queryParams.has('p')) {
        const page = queryParams.get('p')
        history.push(page)
        return
      } else if (queryParams.has('print')) {
        const configCode = queryParams.get('configCode') || ''
        history.push(`/print/${configCode}${queryParams.has('download') ? '?download=1' : ''}`)
        return
      }
      
      const { setConfigCode } = this.props
      if (window) {
        window.scrollTo(0, 0)
      }

      this.clearConfigurations()
      setConfigCode('')
    }

    clearConfigurations() {
      const { setConfiguration, stash } = this.props
      setConfiguration({})
      stash({})
    }

    selectVehicle(data) {
      const { history } = this.props
      history.push(`/build/${data.id}`)
    }

    render() {
      const { classes, data, env } = this.props
      const byCategory = {}
      data.forEach(d => {
        if (!byCategory[d.category]) {
          byCategory[d.category] = []  
        }
        byCategory[d.category].push(d)
      })

      return (
        <Frame className={['Build', classes?.page]} style={styles?.page}>
          <AnalyticsProvider.PageView url="/build" />
            <ToastContainer autoClose={5000} />
            <Body>
              <Frame className={['Build__container', classes?.container]} style={styles?.container}>
                <Frame className={['Build__left-container', classes?.leftContainer]}>
                  <BuildCodeButton />
                  <AboutSirius />
                </Frame>
                <Frame className={['Build__grid-container', classes?.gridContainer]}>
                  <ApiProvider env={env} Loading={<Loading />} endpoint={API.MODELS}>
                    {data && (
                      <>
                        <VehicleRow
                          title="Sirius 2"
                          description="Sirius is the brightest star in the low speed vehicle galaxy"
                          data={byCategory['Sirius 2']}
                          selectVehicle={selected => this.selectVehicle(selected)}
                        />
                        <VehicleRow
                          title="Sirius 4"
                          description="Sirius is the brightest star in the low speed vehicle galaxy"
                          data={byCategory['Sirius 4']}
                          selectVehicle={selected => this.selectVehicle(selected)}
                        />
                      </>
                    )}
                  </ApiProvider>
                </Frame>
              </Frame>
            </Body>
          <ModalBackground>
            <Modal type="standard-features">
              <StandardFeatures />
            </Modal>
            <Modal type="load-build">
              <LoadBuildModal />
            </Modal>
            <Modal type="vehicle-select">
              <VehicleSelectModal />
            </Modal>
          </ModalBackground>
        </Frame>
      )
    }
  }
}

const mapDispatchToProps = {
  openModal: Actions.Ui.openModal,
  setConfigCode: Actions.Configuration.setConfigCode,
  setConfiguration: Actions.Configuration.set,
  stash: Actions.Configuration.stash
}

const mapStateToProps = (state, ownProps) => {
  const { api } = state
  const { hasLoaded } = api
  const db = new BrightsmithDb(api)
  const models: Db.ModelEntry[] = db.getModels()
  return {
    data: DataUtils.sort(models),
    hasLoaded
  }
}

const style = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '38px',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxHeight: '100%',
    maxWidth: 1300,
    padding: 32,
    [`@media (max-width: ${theme.breakpoints.mobile}px)`]: {
      flexDirection: 'column'
    }
  },
  leftContainer: {
    flex: '1 1 0',
    [`@media (max-width: ${theme.breakpoints.mobile.maxWidth})`]: {
    }
  },
  gridContainer: {
    flex: '4 1 0',
    position: 'relative'
  },
  header: {
    fontSize: 50,
    fontWeight: '100',
    textAlign: 'center',
    marginTop: 30,
    marginBottom: 15
  },
  page: {
    minHeight: 1050,
    ...theme?.defaults?.Page
  },
  subHeader: {
    fontSize: 18,
    fontWeight: '300',
    lineHeight: '27px',
    marginBottom: 35,
    textAlign: 'center'
  }
})

export { Build, mapDispatchToProps, mapStateToProps, name, style, withRouter }