import React, { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { AnalyticsProvider } from 'brightsmith-analytics'
import { ApiProvider, API } from '../ApiProvider'
import { Api } from 'brightsmith-core'
import { Actions } from 'brightsmith-redux'
import RulesApi from '../RulesApi'
import { Types } from 'brightsmith-core'
import { Db } from 'brightsmith-core'
import { Utils } from 'brightsmith-ui'
import { faChevronLeft, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import closeIcon from '../assets/icon-close.png'
import { setActiveFooterTab, setActiveSummaryTab } from '../store/actions'

const { BrightsmithDb } = Db
const { StyleUtils } = Utils

Api.init(process.env)
let rulesEngineHasInit = false
interface Props extends Types.BaseUIProps {
  activeFooterTab: number
  activeSummaryTab: number
  attributes: any
  classes: Types.Classes
  configCode?: string
  configuration: Types.Configuration
  countryCode: string
  currentModel?: any  // TODO: update Db.ModelEntry with "year"
  data?: Types.GenericObject
  env: Types.Env
  error?: boolean
  history?: any
  match: Types.GenericObject
  modalType: string
  openModal: Function
  price: Number
  properties: any
  reset: Function
  saveLead: Function
  seating: Types.Id
  setConfigCode: Function
  suspension: Types.Id
  fullScreen: boolean
  leadData?: any
  leadIsValid: boolean
  setActiveFooterTab: Function
  setActiveSummaryTab: Function
  setConfiguration: Function
  setFullScreen: Function
}

interface State {
  configCode?: string
  dealerLoading: boolean
  leadSubmitted: boolean
  mobileMenuOpen: boolean
  renderControlsType: string
  renderOpts: Types.GenericObject
  showLeadsForm: boolean
  showMobileActions: boolean
  titleBarOpts: Types.GenericObject
  dealerResult
  zipCodeInput: string
  screenWidth
}

const name = 'Configurator'
const CONFIGURE_TAB = 0
const SUMMARY_TAB = 1
const MOBILE_BREAKPOINT = 991

const initialOpts = {
  dealerLoading: false,
  renderControlsType: 'toggle',
  renderOpts: { fullScreen: true, magnify: false, title: false },
  titleBarOpts: { price: true, title: true },
  dealerResult: null,
  zipCodeInput: '',
  screenWidth: 0
}

const Configurator = ({ Frame, Body, Footer, Attribute, Category, Image, ModalBackground, Modal, Property, Render, Summary, ScrollListener, TitleBar, TitleBarControls, FeatureSelector, LeadsForm, Loading, Button, CategoryHeader, Text, SubmitButtons, ConnectButtons, ThankYou, Icon, LoadBuildModal, SaveBuildModal, DownloadBrochureModal, SwitchModal, EstimatePaymentModal, styles, AnalyticsApi }: Types.Components) => {
  return class extends React.PureComponent<Props, State> {
    bodyRef: any
    innerRef: any

    constructor(props) {
      super(props)

      this.state = {
        leadSubmitted: false,
        mobileMenuOpen: false,
        showLeadsForm: false,
        showMobileActions: false,
        ...initialOpts
      }

      this.handleNavigation = this.handleNavigation.bind(this)
      this.renderFeatureSelector = this.renderFeatureSelector.bind(this)
      this.renderLeadsForm = this.renderLeadsForm.bind(this)
      this.renderTitleBarAfter = this.renderTitleBarAfter.bind(this)
      this.scrollToTop = this.scrollToTop.bind(this)
      this.submitLead = this.submitLead.bind(this)
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      this.bodyRef = createRef()
      this.innerRef = createRef()
    }

    componentDidMount() {  
      if (window) {
        window.addEventListener('resize', this.updateWindowDimensions)
      }
      this.updateWindowDimensions()
    }

    componentWillUnmount() {
      if (window) {
        window.removeEventListener('resize', this.updateWindowDimensions)
      }
      rulesEngineHasInit = false
    }

    componentDidUpdate(prevProps) {
      const { error, match } = this.props
      if (error) {
        window.location.href = '/build?e=1'
        return
      }
      const navigatedToNewModel = prevProps.match?.params.modelId !== match?.params.modelId
      if (navigatedToNewModel) {
        // Reinitialize RulesEngine
        rulesEngineHasInit = false
      }

      const showLeadsForm = (this.props.activeFooterTab === 2)
      if (showLeadsForm !== this.state.showLeadsForm) {
        this.setState({ showLeadsForm })
      }
    }

    handleNavigation(property) {
      const { match, openModal } = this.props
      const { params } = match
      const { modelId } = params
      let to
      switch (property.id) {
        case 'lifted': {
          if (['sirius-2', 'sirius-2-plus-2'].includes(modelId)) {
            to = 'sirius-2-plus-2-lifted'
          } else if (['sirius-4', 'sirius-4-plus-2'].includes(modelId)) {
            to = 'sirius-4-plus-2-lifted'
          }
          break
        }
        case 'non-lifted': {
          if (modelId === 'sirius-2-plus-2-lifted') {
            to = 'sirius-2-plus-2'
          } else if (modelId === 'sirius-4-plus-2-lifted') {
            to = 'sirius-4-plus-2'
          }
          break
        }
      }
      
      if (to) {
        openModal('switch-modal', { from: modelId, to })
      }
    }

    updateWindowDimensions() {
      this.setState({ screenWidth: window.innerWidth })
    }

    renderDealerResult(dealer, classes) {
      if (dealer) {
        const { name, address, city, state, zip, phone, distance } = dealer
        return (
          <Frame className={['LeadsForm__dealer', classes?.dealer]}>
            <Text className={['LeadsForm__dealer-name', classes?.dealerName]}>{name}</Text>
            {(!city && !state) ? (
              <Text className={['LeadsForm__dealer-location', classes?.dealerLocation]}>{address}</Text>
            ) : (
              <Text className={['LeadsForm__dealer-location', classes?.dealerLocation]}>{address} {city}, {state} {zip}</Text>
            )}
            <Text className={['LeadsForm__dealer-location', classes?.dealerLocation]}>{phone}</Text>
            {!!distance && <Text className={['LeadsForm__dealer-distance', classes?.dealerDistance]}>{distance} Miles Away</Text>}
          </Frame>
        )
      }
      return (
        <Frame className={['LeadsForm__dealer', classes?.dealer]}>
          <Text className={['LeadsForm__dealer-name', classes?.dealerName]}>Star EV</Text>
          <Text className={['LeadsForm__dealer-location', classes?.dealerLocation]}>378 Neely Ferry Road, Simpsonville, SC 29680</Text>
        </Frame>
      )
    }

    renderFeatureSelector() {
      const { setActiveFooterTab } = this.props
      const { screenWidth } = this.state
      const rulesApiSelect = (attributeId, propertyId) => RulesApi.select(attributeId, propertyId)
      const showLeadsForm = () => {
        setActiveFooterTab(2)
      }
      const propertyComponents = {
        'lsv-package': 'LSV'
      }

      const property = props => <Property onClick={this.handleNavigation} opts={{ clickAgainToDeselectRadio: true, infoButton: 'body' }} {...props} />
      const attribute = props => <Attribute propertyComponents={propertyComponents} Property={property} select={rulesApiSelect} {...props} />
      const category = props => <Category Attribute={attribute} {...props} />
      
      return (
        <FeatureSelector
          Category={category}
          CategoryHeader={<CategoryHeader placeholder="Option Select" select={true} />}
          LeadsForm={<ConnectButtons showLeadsForm={showLeadsForm} />}
          opts={{ scrollToAdjustment: 300 }}
        />
      )
    }

    renderLeadsForm() {
      const { classes, leadData, saveLead } = this.props
      const { configCode, dealerLoading, dealerResult, leadSubmitted } = this.state

      const hideLeadsForm = () => {
        setActiveSummaryTab(CONFIGURE_TAB)
        this.setState({ showLeadsForm: false })
      }
      const setDealerLoading = dealerLoading => this.setState({ dealerLoading })
      const setDealerResult = dealerResult => {
        this.setState({ dealerResult })
        saveLead('findADealer', dealerResult)
      }

      const fields = {
        firstName: {
          order: 1,
          placeholder: 'First name*'
        },
        lastName: {
          order: 2,
          placeholder: 'Last name*'
        },
        email: {
          order: 3,
          placeholder: 'Email*'
        },
        state: null,
        zip: {
          order: 5,
          placeholder: 'Zip code*'
        },
        address: null,
        address2: null,
        city: null,
        country: null,
        phone: {
          half: true,
          order: 6,
          placeholder: 'Phone number',
          required: false
        },
        tradeIn: {
          type: 'check',
          half: true,
          order: 8,
          label: 'I have a vehicle to trade in',
        },
        required: {
          type: 'html',
          half: true,
          order: 9,
          content: (
            <Text className={['LeadsForm__required', classes?.required]}>* Required</Text>
          )
        },
        signUp: null,
        findADealer: {
          type: 'html',
          half: true,
          content: (
            <Button
              disabled={!leadData['zip']}
              className={['LeadsForm__find-dealer', classes?.findDealerButton]}
              style={styles?.findDealerButton}
              onClick={() => {
                const { zip } = leadData
                if (dealerLoading) {
                  return
                }

                setDealerLoading(true)
                if (zip) {
                  Api.dealer.GET(zip)
                    .then(response => {
                      if (response.data.length > 0) {
                        const dealer = response.data[0]
                        setDealerResult(dealer)
                      } else {
                        setDealerResult({
                          name: 'Star EV',
                          address: '378 Neely Ferry Road, Simpsonville, SC, 29680'
                        })
                      }
                      setDealerLoading(false)
                    })
                    .catch(err => {
                      console.error(err)
                      setDealerLoading(false)
                    })
                  return
                }
                setDealerLoading(false)
              }}>
              {dealerLoading ? <Loading backgroundColor="#FFF" color="#AAA" height={30} width={30} /> : 'Find Dealer*'}
            </Button>
          ),
          required: true,
          order: 7
        },
        dealerResult: {
          type: 'html',
          content: dealerResult !== null ? this.renderDealerResult(dealerResult, classes) : null,
          order: 10
        }
      }
      
      return leadSubmitted ?
        <ThankYou
          configCode={configCode}
          dealer={dealerResult}
        />
        :
        <LeadsForm
          instructions="Please complete this form and a StarEV dealer will contact you shortly regarding your build."
          fields={fields}
          onCancel={hideLeadsForm}
          onSubmit={this.submitLead}
          SubmitButtons={SubmitButtons}
          title="Connect with a dealer"
        />
    }

    renderTitleBarAfter(classes) {
      const { mobileMenuOpen } = this.state

      return (
        <>
          {!mobileMenuOpen ? (
            <Icon
              className={['TitleBarControls__mobile-toggle', classes.mobileToggle]}
              icon={faEllipsisV}
              onClick={() => {
                this.setState({ mobileMenuOpen: !mobileMenuOpen })
              }} 
            />
           ) : (
            <Image
              className={['TitleBarControls__mobile-toggle', classes.mobileToggle]}
              src={closeIcon}
              onClick={() => {
                this.setState({ mobileMenuOpen: !mobileMenuOpen })
              }} 
            />
           )}
          <TitleBarControls />
        </>
      )
    }

    scrollToTop() {
      if (window) {
        window.scrollTo(0, 0)
      }
    }

    submitLead(formData: Types.GenericObject, model: Db.ModelEntry, configuration: Types.Configuration) {
      const { attributes, properties, configCode, countryCode, price, setConfigCode } = this.props
      const { dealerResult } = this.state

      const lead = {
        formData,
        model,
        configuration,
        dealerInfo: {
          ...dealerResult
        },
        attributes,
        properties,
        configCode,
        countryCode,
        price,
      }
      console.log('sending lead', lead)
      Api.lead.POST(lead)
        .then(resp => { 
          const { data } = resp
          const { configCode } = data
          AnalyticsApi.track({ action: 'Submitted lead', category: 'Configurator', label: configCode })
          this.setState({
            configCode,
            leadSubmitted: true
          }, () => {
            setConfigCode(configCode)
            Actions.clearLead()
          })
        })
        .catch(err => {
          // TODO handle error
        })
    }

    render() {
      const { activeFooterTab, activeSummaryTab, classes, configuration = {}, currentModel, env, history, match, fullScreen, modalType, setConfiguration } = this.props
      const { mobileMenuOpen, renderOpts, screenWidth, showLeadsForm, titleBarOpts } = this.state
      const { params } = match
      const bodyClasses = {
        'scrolled-to-Body': { comparator: '>=', field: 'offsetTop', ref: this.bodyRef },
      }
      const title = currentModel?.name

      const icon = (
        <Icon icon={faChevronLeft} className={['Configurator__title-bar-icon', classes.ConfiguratorTitleBarIcon]} />
      )
      const breadcrumbs = [
        { onClick: () => history.push('/build'), title: '', left: icon }
      ]
      const closeMobileMenu = () => this.setState({ mobileMenuOpen: false })

      return (
        <Frame className={['Configurator', classes.page, {[classes.fullPage]: fullScreen}]} style={styles?.page}>
          <Frame className={['Configurator__wrapper', classes.wrapper]} style={styles.wrapper}>
            <AnalyticsProvider.PageView url={`build/${params.modelId}`} />
            <ToastContainer autoClose={5000} />
            <ApiProvider env={env} Loading={<Loading />} endpoint={API.MODEL} params={{ modelId: params.modelId }}>
              {!fullScreen && <TitleBar after={this.renderTitleBarAfter(classes)} breadcrumbs={breadcrumbs} title={title} opts={titleBarOpts} />}
              <Body forwardRef={this.bodyRef} fullScreen={fullScreen}>
                <Frame className={['Configurator__mobile-dropdown-bg', classes.mobileBg, {[classes.visible]: mobileMenuOpen}]} onClick={() => closeMobileMenu()}>
                  <TitleBarControls mobile={true} />
                </Frame>
                <ScrollListener bodyClasses={bodyClasses} />
                <Frame className={['Configurator__inner', classes.inner, {[classes?.innerMobileWithLeadsForm]: showLeadsForm}]} ref={this.innerRef} style={styles?.inner}>
                  {(activeSummaryTab == SUMMARY_TAB || (activeFooterTab === 2 && screenWidth > MOBILE_BREAKPOINT)) && (
                    <Frame className={['Configurator__summary-wrapper', classes.summaryWrapper]} style={styles?.summaryWrapper}>
                      <Summary />
                    </Frame>
                  )}
                  <Render
                    env={env}
                    Loading={<Loading />}
                    modelId={params.modelId}
                    opts={renderOpts}
                    type="canvas"
                  />
                  <Frame className={['Configurator__feature-selector-wrapper', classes.featureSelectorWrapper, {[classes.hidden]: fullScreen}]} style={styles?.featureSelectorWrapper}>
                    {showLeadsForm ? this.renderLeadsForm() : this.renderFeatureSelector()}
                  </Frame>
                </Frame>
              </Body>
            </ApiProvider>
            <ModalBackground>
              <Modal type="info" />
              <Modal type="thumbnail-only" />
              <Modal type="conflict" />
              {modalType === 'switch-modal' && <Modal type="switch-modal" onCancel={() => {
                RulesApi.select('suspension-0', (configuration['suspension-0'] === 'lifted') ?
                  'non-lifted' : 'lifted')
              }}>
                <SwitchModal />
              </Modal>}
              <Modal type="load-build">
                <LoadBuildModal />
              </Modal>
              <Modal type="save-build">
                <SaveBuildModal />
              </Modal>
              <Modal type="download-brochure">
                <DownloadBrochureModal />
              </Modal>
              <Modal type="estimate-payment">
                <EstimatePaymentModal />
              </Modal>
            </ModalBackground>
          </Frame>
          <Footer screenWidth={screenWidth} />
        </Frame>
      )
    }
  }
}

const mapDispatchToProps = {
  openModal: Actions.Ui.openModal,
  reset: Actions.Configuration.reset,
  saveLead: Actions.saveLead,
  setActiveFooterTab,
  setActiveSummaryTab,
  setConfigCode: Actions.Configuration.setConfigCode,
  setConfiguration: Actions.Configuration.set,
  setFullScreen: Actions.Ui.setFullScreen
}

const mapStateToProps = (state, ownProps) => {
  const { app, api, ui, leads, starEV } = state
  const { countryCode } = app
  const { configCode, configuration, error } = api
  const { activeFooterTab, activeSummaryTab } = starEV
  const { fullScreen, modalType } = ui
  const db = new BrightsmithDb(api)
  const data = db.getAllData()
  const currentModel = db.getCurrentModel()

  const attributes = Object.values(db.getAttributesFromConfiguration(configuration))
  const properties = Object.values(db.getPropertiesFromConfiguration(configuration))
  const totalPrice = db.calculateTotalPrice(configuration, currentModel, countryCode)

  // Initialize the rules engine with the data once
  const dataHasInit = Boolean(data.properties)
  if (api.hasLoaded && !rulesEngineHasInit && dataHasInit) {
    // When switching models, make sure we init with the new data
    if (data.currentConfigCode || data.currentModelId === ownProps.match?.params?.modelId) {
      RulesApi.init(data)
      rulesEngineHasInit = true  // if you need to reload the data, invalidate this first
    }
  }

  const { leadIsValid } = leads
  return {
    activeFooterTab,
    activeSummaryTab,
    attributes,
    configuration: data.configuration || ownProps.location?.state?.configuration || api?.configuration,
    configCode: data.currentConfigCode || configCode,
    currentModel,
    data,
    error,
    fullScreen,
    leadData: leads,
    leadIsValid,
    location: ownProps.location?.pathname,
    modalType,
    properties,
    price: totalPrice
  }
}

const style = theme => ({
  inner: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end',
    margin: '0 auto',
    position: 'relative',
    width: '100%',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      flexDirection: 'column',
      height: '100%',
      margin: 0,
      maxHeight: '100%',
      paddingTop: 52
    },
  },
  innerMobileWithLeadsForm: {
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      paddingTop: '135px !important'
    },
  },
  disclaimer: {
    color: '#896844',
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: '400',
    marginTop: 20
  },
  featureSelectorWrapper: {
    display: 'flex',
    maxWidth: 428,
    minWidth: 300,
    order: 2,
    width: '33%',
    zIndex: 5,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      height: '100%',
      width: '100%',
      maxHeight: '100%',
      maxWidth: '100%'
    }
  },
  findDealerButton: {
    border: 0,
    color: 'white',
    cursor: 'pointer',
    backgroundColor: 'black',
    fontWeight: 600,
    height: 40,
    padding: '0px !important',
    position: 'relative',
    textTransform: 'uppercase',
    width: '100%',
    '&:disabled': {
      cursor: 'not-allowed',
      backgroundColor: '#E3E3E3',
      color: '#A3A2A2'
    }
  },
  fullPage: {
    maxWidth: 'none !important'
  },
  hidden: {
    display: 'none !important'
  },
  iconSpecs: {
    marginLeft: 7,
    width: 16,
  },
  mobileBg: {
    '-webkit-overflow-scrolling': 'touch',
    backgroundColor: 'rgba(0, 0, 0, .5)',
    bottom: 0,
    display: 'none',
    left: 0,
    overflow: 'scroll',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 100,
    [`@media (min-width: ${theme?.breakpoints?.mobile}px)`]: {
      display: 'none !important'
    }
  },
  mobileHeader: {
    display: 'none',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      display: 'block',
      paddingBottom: 8,
      position: 'relative',
      width: '75%',
    },
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: 700,
    ...StyleUtils.margin('0 auto'),
    maxHeight: '100%',
    minHeight: '100%',
    position: 'relative',
    ...theme?.defaults?.Page
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    ...StyleUtils.margin('0 auto'),
    maxHeight: '100%',
    maxWidth: 1300,
    minHeight: 500,
    ...StyleUtils.padding('0 10px'),
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      padding: 0,
      width: '100%'
    }
  },
  required: {
    fontSize: 11,
    textAlign: 'right',
    width: '100%'
  },
  slideContent: {
    maxHeight: '100%',
    width: 'auto'
  },
  slideThumb: {
    maxHeight: '100%',
    width: 'auto'
  },
  spacer: {
    display: 'none',
    flex: 2,
    maxWidth: 400,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      display: 'none'
    }
  },
  summaryToggleDesktop: {
    marginTop: 10,
    paddingLeft: 30,
    paddingRight: 30,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      display: 'none'
    }
  },
  summaryToggleMobile: {
    display: 'none',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      display: 'flex',
      paddingBottom: 8
    }
  },
  summaryWrapper: {
    background: 'rgba(255, 255, 255, .7)',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    maxHeight: '100%',
    maxWidth: 871,
    position: 'absolute',
    top: 0,
    width: '67%',
    zIndex: 10,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      width: '100%',
      top: 200,
      // position: 'fixed',
      padding: 0,
      bottom: 0
    }
  },
  mobileToggle: {
    [`@media (min-width: ${theme?.breakpoints?.mobile}px)`]: {
      display: 'none'
    },
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    padding: 12,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 24,
    maxHeight: 23,
    marginTop: 14,
    position: 'absolute',
    right: 8,
    top: 3
  },
  ConfiguratorTitleBarIcon: {
    width: 15,
    height: 25,
    '&>*:hover': {
      cursor: 'pointer'
    }
  },
  dealer: {
    lineHeight: '22px'
  },
  dealerName: {
    fontWeight: 'bold',
    fontSize: 18
  },
  dealerLocation: {
    fontSize: 18
  },
  dealerDistance: {
    fontSize: 14
  },
  visible: {
    display: 'flex'
  }
})

export { Configurator, mapDispatchToProps, mapStateToProps, name, style, withRouter, CONFIGURE_TAB, SUMMARY_TAB }