import React from 'react'
import { withRouter } from 'react-router-dom'
import { Db, Types } from 'brightsmith-core'
import { Actions } from 'brightsmith-redux'
import { Utils } from 'brightsmith-ui'
import greenPlus from '../assets/green-plus.png'
import redDash from '../assets/red-dash.png'

const { BrightsmithDb } = Db
const { ImageUtils, StyleUtils } = Utils


interface Props extends Types.BaseUIProps {
    classes: Types.Classes
    closeModal: Function
    env: Types.Env
    history: any
    modalData: Types.GenericObject
    model: any
}

const name = 'SwitchModal'

const SwitchModal = ({ Frame, Text, Image, Button, styles }: Types.Components) => {
    return class extends React.Component<Props> {
        constructor(props: Props) {
            super(props)

            this.cancel = this.cancel.bind(this)
            this.proceed = this.proceed.bind(this)
        }

        cancel() {
            const { closeModal } = this.props
            closeModal()
        }

        getThumbnailUrl(fromModel: Types.GenericObject, env: Types.Env, model: Db.ModelEntry) {
            return ImageUtils.getThumbnailUrl(env, model, fromModel.thumbnail)
        }

        proceed(to) {
            const { closeModal, history } = this.props
            history.push(`/build/${to}`)
            closeModal()
        }

        render() {
            const { after, before, classes, env, modalData, model } = this.props
            const { from, to } = modalData
            const fromModel = model.allModels[from]
            const toModel = model.allModels[to]
            
            return (
                <Frame className={['Modal__content', classes?.content]} style={styles?.content}>
                    {before}
                    <Frame className={['Modal__title-wrapper', classes?.titleWrapper]} style={styles?.titleWrapper}>
                        <Text className={['Modal__title', classes?.title]} style={styles?.title}>Selection change required</Text>
                        <Text className={['Modal__sub-title', classes?.subTitle]} style={styles?.subTitle}>The selection is not possible based upon previous selections.</Text>
                    </Frame>
                    <Frame className={['Modal__subtractions', classes?.subtractions]} style={styles?.subtractions}>
                        <Frame className={['Modal__row', classes?.row]} style={styles?.row}>
                            <Image className={['Modal__icon', classes?.icon]} style={styles?.icon} src={redDash} />
                            <Text className={['Modal__action', classes?.action]} style={styles?.action}>Remove</Text>
                            <Frame className={['Modal__thumbnailWrapper', classes?.thumbnailWrapper]} style={styles?.thumbnailWrapper}>
                                {fromModel.thumbnail && (
                                    <Image className={['Modal__thumbnail', classes?.thumbnail]} style={styles?.thumbnail} src={this.getThumbnailUrl(fromModel, env, model)} />
                                )}
                            </Frame>
                            <Frame className={['Modal__option-wrapper', classes?.optionWrapper]} style={styles?.optionWrapper}>
                                <Text className={['Modal__option-title', classes?.optionTitle]} style={styles?.optionTitle}>
                                    {fromModel.name}
                                </Text>
                                <Text className={['Modal__option-desc', classes?.optionDesc]} style={styles?.optionDesc}>
                                    
                                </Text>
                            </Frame>
                            <Text className={['Modal__optionPrice', classes?.optionPrice]} style={styles?.optionPrice}>
                                
                            </Text>
                        </Frame>
                    </Frame>
                    <Frame className={['Modal__additions', classes?.additions]} style={styles?.additions}>
                        <Frame className={['Modal__row', classes?.row]} style={styles?.row}>
                            <Image className={['Modal__icon', classes?.icon]} style={styles?.icon} src={greenPlus} />
                            <Text className={['Modal__action', classes?.action]} style={styles?.action}>Add</Text>
                            <Frame className={['Modal__thumbnailWrapper', classes?.thumbnailWrapper]} style={styles?.thumbnailWrapper}>
                                {toModel.thumbnail && (
                                    <Image className={['Modal__thumbnail', classes?.thumbnail]} style={styles?.thumbnail} src={this.getThumbnailUrl(toModel, env, model)} />
                                )}
                            </Frame>
                            <Frame className={['Modal__option-wrapper', classes?.optionWrapper]} style={styles?.optionWrapper}>
                                <Text className={['Modal__option-title', classes?.optionTitle]} style={styles?.optionTitle}>
                                    {toModel.name}
                                </Text>
                                <Text className={['Modal__option-desc', classes?.optionDesc]} style={styles?.optionDesc}>
                                    
                                </Text>
                            </Frame>
                            <Text className={['Modal__optionPrice', classes?.optionPrice]} style={styles?.optionPrice}>
                                
                            </Text>
                        </Frame>
                    </Frame>
                    <Frame className={['Modal__button-wrapper', classes?.buttonWrapper]} style={styles?.buttonWrapper}>
                        <Button className={['Modal__proceed', classes?.proceed]} onClick={() => this.proceed(to)}>Proceed</Button>
                        <Button className={['Modal__cancel', classes?.cancel]} onClick={this.cancel}>Cancel</Button>
                    </Frame>
                    {after}
                </Frame>
            )
        }
    }
}

const mapDispatchToProps: Types.GenericObject = {
    closeModal: Actions.Ui.closeModal,
}

const mapStateToProps = (state, ownProps) => {
    const { api, app, ui } = state
    const { env } = app
    const { modalData } = ui

    const db = new BrightsmithDb(api)
    const model = db.getCurrentModel()

    return {
        env,
        modalData,
        model
    }
}

const style = theme => ({
    action: {
        alignSelf: 'center',
        fontWeight: 'bold',
        marginLeft: 12,
        width: 70
    },
    buttonWrapper: {
        marginTop: 30,
        [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        ...theme?.ConflictModal?.buttonWrapper
    },
    cancel: {
        backgroundColor: '#A3A2A2',
        border: 0,
        boxSizing: 'border-box',
        color: '#000',
        cursor: 'pointer',
        fontSize: 25,
        fontWeight: 'bold',
        height: 60,
        textTransform: 'uppercase',
        width: 256,
        ...theme?.ConflictModal?.cancel
    },
    icon: {
        alignSelf: 'center',
        display: 'flex',
        height: 36,
        width: 36,
        ...theme?.ConflictModal?.icon
    },
    optionDesc: {
        fontSize: 16,
        ...theme?.ConflictModal?.optionDesc
    },
    optionPrice: {
        alignSelf: 'center',
        display: 'flex',
        fontSize: 20,
        ...theme?.ConflictModal?.optionPrice
    },
    optionTitle: {
        fontSize: 20,
        ...theme?.ConflictModal?.optionTitle
    },
    optionWrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        ...theme?.ConflictModal?.optionWrapper
    },
    proceed: {
        backgroundColor: '#000000',
        border: 0,
        boxSizing: 'border-box',
        color: '#FFF',
        cursor: 'pointer',
        fontSize: 25,
        fontWeight: 'bold',
        marginRight: 20,
        height: 60,
        textTransform: 'uppercase',
        width: 256,
        [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
            marginBottom: 10,
            marginRight: 0
        },
        ...theme?.ConflictModal?.proceed
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 10,
        paddingTop: 10,
        width: '100%',
        ...theme?.ConflictModal?.row
    },
    subtitle: {
        fontSize: 28,
        marginBottom: 20,
        ...theme?.ConflictModal?.subTitle
    },
    subtractions: {
        ...StyleUtils.borderBottom('1px solid #707070')
    },
    title: {
        fontSize: 48,
        fontWeight: 'bold',
        [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
            fontSize: 24
        },
        ...theme?.ConflictModal?.title
    },
    thumbnail: {
        height: '100%',
        width: 'auto',
        ...theme?.ConflictModal?.thumbnail
    },
    thumbnailWrapper: {
        alignSelf: 'center',
        ...StyleUtils.border('1px solid #E3E3E3'),
        display: 'flex',
        height: 90,
        justifyContent: 'center',
        marginLeft: 10,
        marginRight: 15,
        overflow: 'hidden',
        width: 90,
        ...theme?.ConflictModal?.thumbnailWrapper
    },
    titleWrapper: {
        ...theme?.ConflictModal?.titleWrapper
    }
})

export { SwitchModal, mapDispatchToProps, mapStateToProps, name, style, withRouter }
