import React from 'react'
import { Types } from 'brightsmith-core'
import { Actions } from 'brightsmith-redux'
import { Utils } from 'brightsmith-ui'

const { StyleUtils } = Utils

interface Props extends Types.BaseUIProps {
  classes: Types.Classes
  closeModal: Function
  data?: any
}

interface State {
  code: string
}

const name = 'LoadBuildModal'

const LoadBuildModal = ({ Frame, Text, Input, Button, style }: Types.Components) => {
  return class extends React.Component<Props, State> {
    constructor(props) {
      super(props)

      this.state = {
        code: ''
      }

      this.loadBuild = this.loadBuild.bind(this)
      this.onChange = this.onChange.bind(this)
    }

    loadBuild() {
      const { code } = this.state
      window.location.href = `/build/c_${code}`
    }

    onChange(e) {
      this.setState({
        code: e.target.value
      })
    }

    render() {
      const { classes } = this.props
      const { code } = this.state
      return (
        <Frame className={['LoadBuildModal__container', classes?.container]}>
          <Text className={['LoadBuildModal__title', classes?.title]}>
            Load your build code
          </Text>
          <Text className={['LoadBuildModal__description', classes?.description]}>
            If you already have a build code, enter it below to view your existing Star EV vehicle
          </Text>
          <Input
            className={['LoadBuildModal__input', classes?.input]}
            onChange={this.onChange}
            placeholder="ex. wjiwbj12537"
            value={code}
            style={style?.input} />
          <Button className={['LoadBuildModel__button', classes?.button]} style={style?.button} onClick={this.loadBuild}>
            Load
          </Button>
        </Frame>
      )
    }
  }
}

const mapDispatchToProps = {
  closeModal: Actions.Ui.closeModal
}

const style = theme => ({
  ...theme?.Modal,
  button: {
    background: '#000',
    borderWidth: 0,
    color: '#fff',
    cursor: 'pointer',
    flex: 1,
    fontSize: 26,
    fontWeight: 'bold',
    minHeight: 60,
    textTransform: 'uppercase',
    width: 250,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      fontSize: 19
    }
  },
  input: {
    ...StyleUtils.border('1px solid #E3E3E3'),
    boxSizing: 'border-box',
    fontSize: 20,
    height: 84,
    padding: 30,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      fontSize: 19
    }
  },
})

export { LoadBuildModal, mapDispatchToProps, name, style }
