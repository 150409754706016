import React from 'react'
import { Types } from 'brightsmith-core'
import * as Actions from '../store/actions'

interface Props extends Types.BaseUIProps {
  actions: Types.GenericObject,
  classes: Types.Classes,
  setModelFilter: Function
}

interface State {
  open: boolean
}

const name = 'ModelDropdown'

const ModelDropdown = ({ Frame, Button, Select, Text, styles }: Types.Components) => {
  return class extends React.Component<Props, State> {
    selectCategory(id) {
      const { setModelFilter } = this.props
      setModelFilter(id)
    }

    render() {
      const { classes } = this.props
      const options: Types.SelectOption[] = [
        {
          label: 'Models: All',
          value: 'all'
        },
        {
          label: 'Models: Personal Transportation',
          value: 'personal-transportation'
        },
        {
          label: 'Model: Golf',
          value: 'golf'
        },
        {
          label: 'Model: Street Legal',
          value: 'street-legal'
        }
      ]
      return (
        <Select
          className={['Model-Dropdown__Select', classes?.select]}
          onChange={(selected: Types.SelectOption) => this.selectCategory(selected.value)}
          options={options}
          placeholder="Select Model Type"
          style={styles?.select} />
      )
    }
  }
}

const mapDispatchToProps = {
  setModelFilter: Actions.setModelFilter
}

const mapStateToProps = (state, ownProps) => {

}

const style = theme => ({
})

export { ModelDropdown, mapDispatchToProps, mapStateToProps, name, style }
