import React from 'react'
import axios from 'axios'
import qs from 'qs'
import { Api, Types } from 'brightsmith-core'

interface Props {
    classes: Types.Classes
    location?: any
    match?: any
}

const name = 'Print'

const Print = ({ Frame, Loading, Text, styles }: Types.Components) => {
    return class extends React.PureComponent<Props> {
        constructor(props) {
            super(props)

            this.loadPDF = this.loadPDF.bind(this)
        }

        componentDidMount() {
            this.loadPDF()
        }

        loadPDF() {
            const { location, match } = this.props
            const queryParams = qs.parse(location?.search.slice(1))
            const { apiRoot, configuration, countryCode, download, model } = queryParams
            const configCode = match?.params?.configCode || queryParams.configCode

            const getPDF = code => 
                Api.pdf.GET(code, { download })
                    .then(resp => {
                        const { url } = resp.data
                        console.log('pdf.get', resp)
                        window.location.href = url
                    })
                    .catch(err => console.error(err))

            if (!configCode) {
                const url = `${apiRoot}model/${model.id}`
                return axios
                    .get(url)
                    .then(json => {
                        if (json) {
                            const data = typeof json === 'string' ? JSON.parse(json) : json
                            const { attributes, model, properties } = data.data
                            const { price } = model

                            Api.configuration.POST(configuration, model, { attributes, countryCode, price, properties })
                                .then(resp => {
                                    const configCode = resp?.data
                                    getPDF(configCode)
                                })
                                return
                        }
                    })
            }
            getPDF(configCode)
        }

        render() {
            const { classes } = this.props
            return (
                <Frame className={['Print', classes?.page]} style={styles?.page}>
                    <Text className={['Print__text', classes?.text]}>Building your Star...</Text>
                    <Loading />
                </Frame>
            )
        }
    }
}

const style = theme => ({
  page: {
    alignContent: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    paddingBottom: 100,
    paddingTop: 50,
    position: 'relative',
    width: '100%',
    ...theme?.defaults?.Page
  },
  text: {
      alignSelf: 'center',
      color: theme?.colors?.darkBlue,
      display: 'flex',
      fontSize: 23,
      fontWeight: '700',
      paddingBottom: 100
  }
})

export { Print, name, style }