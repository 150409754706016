import React from 'react'
import { Types } from 'brightsmith-core'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

interface Props extends Types.BaseUIProps {
  classes: Types.Classes
  countryCode: string
  data?: any[]
  description?: string
  modelFilterValue: string
  seatingFilterValue: string
  selectVehicle: Function
  title: string  
}

const name = 'VehicleRow'

const VehicleRow = ({ Frame, Grid, Text, VehicleThumbnail, Icon, styles }: Types.Components) => {
  return class extends React.Component<Props> {
    constructor(props: Props) {
      super(props)
    }

    renderThumbnailDescription(data) {
      const { classes, countryCode } = this.props
      return (
        <>
          <Text className={['VehicleRow__thumbnail-price', classes?.thumbnailPrice]} style={styles?.thumbnailPrice}>
            From ${(data.price[countryCode] || 0).toLocaleString()}
          </Text>
          <Frame className={['VehicleRow__thumbnail-description', classes?.thumbnailDescription]} style={styles?.thumbnailDescription}>
            <Text className={['VehicleRow__thumbnail-seating', classes?.thumbnailSeating]} style={styles?.thumbnailSeating}>
              Seating: {data.seating || '0'}
            </Text>
            <Text className={['VehicleRow__thumbnail-divider', classes?.thumbnailDivider]} style={styles?.thumbnailDivider}>
              |
            </Text>
            <Text className={['VehicleRow__capitalize', classes?.capitalize]} style={styles?.capitalize}>
              Roof: {data.roofing || 'Standard'}
            </Text>
          </Frame>
        </>
      )
    }

    renderInnerButton() {
      const { classes } = this.props
      return (
        <Frame className={['VehicleRow__inner-button', classes?.innerButton]}>
          <Text className={['VehicleRow__button-text', classes?.buttonText]}>Customize</Text>&nbsp;
          <Text className={['VehicleRow__button-text-divider', classes?.buttonTextDivider]}>|</Text>&nbsp;
          <Icon icon={faChevronRight} />
        </Frame>
      )
    }

    render() {
      const { classes, description, selectVehicle, title, seatingFilterValue, modelFilterValue } = this.props
      let { data = [] } = this.props

      if (seatingFilterValue) {
        data = this.applySeatingFilter(data, seatingFilterValue)
      }

      if (modelFilterValue) {
        data = this.applyModelFilter(data, modelFilterValue)
      }

      if (data.length === 0) {
        return null
      }

      return (
        <Frame className={['Build__sirius-2-container']}>
            <Grid
                items={data}
                lg={3}
                md={2}
                sm={1}
                renderItem={item => (
                  <VehicleThumbnail 
                    onSelect={selectVehicle}
                    model={item}
                    description={this.renderThumbnailDescription(item)}
                    innerButton={this.renderInnerButton()} />)
                }
            />
        </Frame>
      )
    }

    applySeatingFilter(data, seatingFilterValue) {
      return data.filter(model => model.seating >= parseInt(seatingFilterValue))
    }

    applyModelFilter(data, modelFilterValue) {
      if (modelFilterValue === 'all') {
        return data
      }
      return data.filter(model => model.vehicleCategories?.includes(modelFilterValue) )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { app, starEV } = state
  const { countryCode } = app

  return {
    seatingFilterValue: starEV.seatingFilterValue,
    modelFilterValue: starEV.modelFilterValue,
    countryCode
  }
}

const style = theme => ({
  innerButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px'
  },
  buttonText: {
    fontFamily: theme.base?.fontFamily,
    fontSize: '18px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  buttonTextDivider: {
    color: '#e3e3e3',
    marginBottom: 0
  },
  capitalize: {
    marginBottom: 0,
    textTransform: 'capitalize'
  },
  thumbnailPrice: {
    fontWeight: '600',
    fontSize: 20,
    lineHeight: '28px',
    marginBottom: 0,
    color: '#4B4B4B'
  },
  thumbnailDescription: {
    padding: '0 12px 8px 12px',
    fontWeight: '300',
    lineHeight: '22px',
    color: '#4B4B4B',
    display: 'flex',
    gap: '8px',
    borderBottom: '1px solid #E3E3E3'
  },
  thumbnailDivider: {
    color: '#A3A2A2',
    fontWeight: '600'
  },
  thumbnailSeating: {
    marginBottom: 0
  }
})

export { VehicleRow, mapStateToProps, name, style }
