import React from 'react'
import { BaseUIProps, Components } from 'brightsmith-core/dist/types'

interface Props extends BaseUIProps {
  onClick?: Function
}

const name = 'InfoButton'

const InfoButton = ({ Text, styles }: Components) => {
  return class extends React.PureComponent<Props> {
    constructor(props: Props) {
      super(props)
    }

    handleClick(e: Event, onClick?: Function) {
      if (onClick) {
        onClick(e)
      }
    }

    render() {
      const { after, before, classes, onClick } = this.props
      return (
        <Text 
          className={['InfoButton__info-button', classes?.infoButton]}
          onClick={(e) => this.handleClick(e, onClick)}
          style={styles?.infoButton}>
          {before}
          Info
          {after}
        </Text>
      )
    }
  }
}

const style = theme => ({
  infoButton: {
    backgroundColor: '#aaa',
    borderRadius: 10,
    color: '#fff',
    cursor: 'pointer',
    fontSize: 12,
    fontStyle: 'italic',
    lineHeight: 1.5,
    marginLeft: 'auto',
    marginTop: 4,
    height: 17,
    textAlign: 'center',
    textIndent: -1,
    width: 17,
    '@mobile': {
      lineHeight: 0,
    },
    ...theme?.InfoButton?.infoButton,
  },
})

export { InfoButton, name, style }
