import React from 'react'
import { Db, Types } from 'brightsmith-core'
import { Utils } from 'brightsmith-ui'

const { ImageUtils, StyleUtils } = Utils

interface Props {
  classes: Types.Classes
  env: Types.Env
  history: Types.GenericObject
  model: Db.ModelEntry
  onSelect?: Function
  description?
  innerButton?
}

const name = 'VehicleThumbnail'

const VehicleThumbnail = ({ Frame, Text, Image, Button, styles, AnalyticsApi }: Types.Components) => {
  return class extends React.PureComponent<Props> {
    constructor(props: Props) {
      super(props)
    }

    goToModel(model, onSelect) {
      AnalyticsApi.track({ action: 'Selected Model', category: 'Configurator', label: model.slug || model.id })
      if (onSelect) {
        onSelect(model)
      }
    }

    renderDescription() {
      const { description } = this.props
      if (description) {
        return description
      } else {
        return this.renderDefaultDescription()
      }
    }

    renderDefaultDescription() {
      const { classes, model } = this.props
      return (
        <Text className={['VehicleThumbnail__description', classes?.description]} style={styles?.description}>{model.description}</Text>
      )
    }

    renderInnerButton() {
      const { innerButton } = this.props
      if (innerButton) {
        return innerButton
      } else {
        return 'Build'
      }
    }

    render() {
      const { classes, env, model, onSelect } = this.props
      const vehicleClass = `vehicle-${model.id}`
      
      return (
        <Frame className={['VehicleThumbnail__container', vehicleClass, classes?.container]} style={styles?.container}>
          {model.display?.thumbnail && 
            <Image className={['VehicleThumbnail__thumbnail', classes?.thumbnail]} style={styles?.thumbnail} onClick={() => this.goToModel(model, onSelect)} src={ImageUtils.getThumbnailUrl(env, model, model.display?.thumbnail)} />
          }
          <Text className={['VehicleThumbnail__title', classes?.title]} style={styles?.title}>{model.name}</Text>
          {this.renderDescription()}
          <Button
            className={['VehicleThumbnail__build', classes?.build]}
            onClick={() => this.goToModel(model, onSelect)}
            style={styles?.build}>
            {this.renderInnerButton()}
          </Button>
        </Frame>
      )
    }
  }
}

const style = theme => ({
  build: {
    color: '#4B4B4B',
    cursor: 'pointer',
    fontSize: 16,
    height: 38,
    lineHeight: '38px',
    padding: 0,
    width: 160,
    '& *:hover': {
      backgroundColor: '#fff',
      color: '#000'
    },
    ...theme?.defaults?.Button,
    ...theme?.VehicleThumbnail?.build
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 60,
    ...StyleUtils.padding('0 15px'),
    textAlign: 'center',
    width: '100%',
    ...theme?.VehicleThumbnail?.container
  },
  description: {
    fontWeight: '300',
    fontSize: 16,
    lineHeight: '24px',
    minHeight: 86,
    ...theme?.VehicleThumbnail?.description
  },
  thumbnail: {
    cursor: 'pointer',
    height: 'auto',
    marginLeft: -10,
    width: '100%',
    ...theme?.VehicleThumbnail?.thumbnail
  },
  title: {
    color: theme?.colors?.darkBlue,
    fontSize: 28,
    fontWeight: '300',
    lineHeight: '30px',
    marginBottom: 20,
    textTransform: 'capitalize',
    ...theme?.VehicleThumbnail?.title
  }
})

const mapStateToProps = (state, ownProps) => {
  const { app } = state
  const { env } = app
  
  return {
    env
  }
}

export { VehicleThumbnail, mapStateToProps, name, style }
