import React from 'react'
import { Types } from 'brightsmith-core'
import { Actions } from 'brightsmith-redux'
import { Utils } from 'brightsmith-ui'
import { setActiveFooterTab, setActiveSummaryTab } from '../store/actions'
import { CONFIGURE_TAB, SUMMARY_TAB } from '../views/Configurator'

const { StyleUtils } = Utils

interface Props extends Types.BaseUIProps {
  activeFooterTab: number,
  activeSummaryTab: number,
  classes: Types.Classes,
  openModal: Function,
  screenWidth: number,
  setActiveFooterTab: Function
  setActiveSummaryTab: Function
}

const name = 'Footer'

const Footer = ({ Frame, Tabs, Price, Text, styles }: Types.Components) => {
  return class extends React.Component<Props> {
    constructor(props: Props) {
      super(props)

      this.handleTabSelected = this.handleTabSelected.bind(this)
    }

    handleTabSelected(tab) {
      const { openModal, setActiveFooterTab, setActiveSummaryTab } = this.props
      setActiveFooterTab(tab)
      switch (tab) {
        case 0:
          setActiveSummaryTab(CONFIGURE_TAB)
          break;
        case 1:
          setActiveSummaryTab(SUMMARY_TAB)
          break;
      }
    }

    render() {
      const { activeFooterTab, classes, openModal, screenWidth } = this.props
      const tabs = screenWidth > 991 ? ['VIEW BUILD', 'VIEW SUMMARY', 'FIND DEALER', 'PAYMENT CALCULATOR'] : ['VIEW BUILD', 'VIEW SUMMARY']

      const goToDealerLocator = () => window.location.href = 'https://www.starev.com/dealer-locator/'
      const openEstimatePayment = () => openModal('estimate-payment')

      return (
        <Frame className={['Footer__container', classes?.container]} style={styles?.container}>
          <Frame className={['Footer__inner', classes?.inner]} style={styles?.inner}>
            <Frame className={['Footer__tabs-container', classes?.tabsContainer]} style={styles?.tabsContainer}>
              <Tabs activeTab={activeFooterTab} onTabSelected={(i) => this.handleTabSelected(i)} overrideOnClick={[null, null, goToDealerLocator, openEstimatePayment]} tabs={tabs} />
            </Frame>
            <Frame className={['Footer__price-container', classes?.priceContainer]} style={styles?.priceContainer}>
              <Price />
              <Text className={['Footer__msrp', classes?.msrp]} style={styles?.msrp}>MSRP Excludes freight and tax</Text>
            </Frame>
          </Frame>
        </Frame>
      )
    }
  }
}

const mapDispatchToProps = {
  openModal: Actions.Ui.openModal,
  setActiveFooterTab,
  setActiveSummaryTab
}

const mapStateToProps = (state, ownProps) => {
  const { starEV } = state
  const { activeFooterTab, activeSummaryTab } = starEV

  return {
    activeFooterTab,
    activeSummaryTab
  }
}

const style = theme => ({
  container: {
    background: '#fff',
    color: '#383838',
    display: 'flex',
    flexDirection: 'row',
    height: 75,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 10,
    }
  },
  inner: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    maxWidth: 1300,
    ...StyleUtils.margin('0 auto')
  },
  msrp: {
    fontSize: 12,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      maxWidth: 100
    }
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  tabsContainer: {
    display: 'flex',
    flex: 1
  }
})

export { Footer, mapDispatchToProps, mapStateToProps, name, style }
