import React from 'react'
import { Actions } from 'brightsmith-redux'
import { ReduxActions } from 'brightsmith-core/dist/types'
import { StyleUtils } from 'brightsmith-ui/dist/utils'
import { Types, Db } from 'brightsmith-core'

interface Props extends Types.BaseUIProps {
  actions: ReduxActions
  classes: Types.Classes
  configCode: string
  openModal: Function
  model
}

const name = 'MobileActionsModal'
const { BrightsmithDb } = Db

const MobileActionsModal = (components: Types.Components) => {
  const { Frame } = components

  return class extends React.Component<Props> {
    constructor(props) {
      super(props)

      this.openPDF = this.openPDF.bind(this)
    }

    openPDF() {
      const { configCode } = this.props
      window.open(`/print/${configCode}`, '_blank')
    }

    render() {
      const { classes, openModal, model } = this.props

      return (
        <>
          <Frame className={['MobileActionsModal__container', classes.container]}>
            <Frame className={['MobilActionsModal__action', classes.action]} onClick={() => openModal('load-build')}>Load build code</Frame>
            <Frame className={['MobilActionsModal__action', classes.action]} onClick={() => openModal('save-build')}>Save build code</Frame>
            <Frame className={['MobilActionsModal__action', classes.action]} onClick={this.openPDF}>Print build</Frame>
            <Frame className={['MobilActionsModal__action', classes.action]} onClick={() => openModal('estimate-payment')}>Estimate monthly payment</Frame>
            <Frame className={['MobilActionsModal__action', classes.action]} onClick={() => openModal('download-brochure', model.literature)}>Download brochure</Frame>
          </Frame>
        </>
      )
    }
  }
}

const mapDispatchToProps = {
  openModal: Actions.Ui.openModal
}

const mapStateToProps = (state, ownProps) => {
  const { api } = state
  const { configCode } = api
  const db = new BrightsmithDb(api)
  const model = db.getCurrentModel()

  return {
    configCode,
    model
  }
}

const style = theme => ({
  action: {
    cursor: 'pointer',
    ...StyleUtils.margin('12px 0')
  },
  container: {
    background: '#fff',
    boxShadow: '0px 6px 4px 0px rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 27,
    fontWeight: 600,
    left: 0,
    paddingLeft: 28,
    paddingRight: 28,
    paddingBottom: 38,
    paddingTop: 14,
    position: 'absolute',
    top: 40,
    width: '100%'
  }
})

export { MobileActionsModal, mapDispatchToProps, mapStateToProps, name, style }
