import React from 'react'
import { Types } from 'brightsmith-core'
import { Utils } from 'brightsmith-ui'

const { StyleUtils } = Utils

interface Props extends Types.BaseUIProps {
  classes: Types.Classes
  dealer?: any
}

const name = 'DealerInfo'

const DealerInfo = ({ Frame, Text, Link, styles }: Types.Components) => {
  return class extends React.Component<Props> {
    constructor(props: Props) {
      super(props)
    }

    render() {
      const { classes, dealer = {} } = this.props
      return (
        <Frame className={['DealerInfo__container', classes?.container]} style={styles?.container}>
          <Text className={['DealerInfo__name', classes?.name]} style={styles?.name}>
            {dealer.name}
          </Text>
          <Text className={['DealerInfo__address', classes?.address]} style={styles?.address}>
            {dealer.address}
          </Text>
          {dealer.phone && (<Text className={['DealerInfo__phone', classes?.phone]} style={styles?.phone}>
            {dealer.phone}
          </Text>)}
          {dealer.email && (<Text className={['DealerInfo__email', classes?.email]} style={styles?.email}>
            <Link href={`mailto:${dealer.email}`}>{dealer.email}</Link>
          </Text>)}
          {dealer.distance && (<Frame>
            <Text className={['DealerInfo__distance', classes?.distance]} style={styles?.distance}>
              {dealer.distance} Miles away
            </Text>
          </Frame>)}
        </Frame>
      )
    }
  }
}

const style = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 18,
    lineHeight: '23px',
    marginBottom: '15px !important'
  },
  distance: {
    fontSize: 14
  },
  name: {
    fontWeight: 'bold'
  }
})

export { DealerInfo, name, style }
