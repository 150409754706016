import React from 'react'
import { withRouter } from 'react-router-dom'
import { Types } from 'brightsmith-core'
import { Utils } from 'brightsmith-ui'
import { Actions } from 'brightsmith-redux'
import SiriusIcon from '../assets/sirius-icon.svg'
import SiriusLogo from '../assets/sirius-logo.svg'

const { ImageUtils, StyleUtils } = Utils
interface Props extends Types.BaseUIProps {
  classes: Types.Classes
  env: Types.Env,
  openModal: Function
}

const name = 'AboutSirius'

const AboutSirius = ({ Frame, Text, Image }: Types.Components) => {
  return class extends React.Component<Props> {
    render() {
      const { classes, env, openModal } = this.props
      return (
        <Frame className={['AboutSirius__container', classes?.container]}>
          <Frame className={['AboutSirius__image-container', classes?.imageContainer]}>
            <Image className={['AboutSirius__icon', classes?.icon]} src={ImageUtils.getAssetUrl(env, SiriusIcon)} />
            <Image className={['AboutSirius__logo', classes?.logo]} src={ImageUtils.getAssetUrl(env, SiriusLogo)} />
          </Frame>
          <Text className={['AboutSirius__description', classes?.description]}>
            The most stylish golf cart and personal transportation vehicle (PTV) to take you where you need to go. Built for performance and fun, Sirius offers extraordinary styling, state-of-the-art technology, and more automotive components than any other golf cart including an LED display, cooler, seat belts, self-canceling turn signals, LED high/low beam headlights, and much more.
          </Text>
          <Frame className={['AboutSirius__call-to-action', classes?.callToAction]} onClick={() => openModal('standard-features')}>
            <Text>The most standard features of any vehicle in its class</Text>
            <Text className={['AboutSirius__standard-features', classes?.standardFeatures]}>
              View all standard features
            </Text>
          </Frame>
        </Frame>
      )
    }
  }
}

const mapDispatchToProps = {
  openModal: Actions.Ui.openModal
}

const mapStateToProps = (state, ownProps) => {
  const { app } = state
  const { env } = app
  return { env }
}

const style = theme => ({
  container: {
    lineHeight: '30px',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center'
    }
  },
  description: {
    fontSize: 20,
    fontWeight: '300',
    padding: '16px 0',
    margin: '16px 0',
    borderTop: '2px solid #707070',
    borderBottom: '2px solid #707070',
    letterSpacing: -0.22,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      border: 0
    }
  },
  imageContainer: {
    display: 'flex',
    [`@media (min-width: ${theme?.breakpoints?.mobile}px)`]: {
      flexDirection: 'column'
    },
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      alignItems: 'center',
      justifyContent: 'center',
      gap: '18px',
      marginTop: 22
    }
  },
  callToAction: {
    backgroundColor: theme?.colors?.darkBlue,
    color: '#fff',
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.6,
    ...StyleUtils.padding('12px 15px')
  },
  standardFeatures: {
    cursor: 'pointer',
    fontSize: 15,
    marginTop: 10,
    paddingTop: 10,
    textTransform: 'uppercase',
    ...StyleUtils.borderTop('1px solid #fff')
  },
  icon: {
    height: 60,
    width: 60,
    marginTop: 10,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      marginTop: 0,
      maxWidth: '100%',
      maxHeight: '100%',
      height: 56
    }
  },
  logo: {
    height: 48,
    width: 248,
    marginTop: 20,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      marginTop: 0,
      maxWidth: '100%',
      maxHeight: '100%',
      height: 36
    }
  }
})

export { AboutSirius, mapDispatchToProps, mapStateToProps, name, style, withRouter }
