import React, { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Api, Db, Types } from 'brightsmith-core'
import { Utils } from 'brightsmith-ui'
import Facebook from '../assets/facebook-icon.png'
import Instagram from '../assets/instagram-icon.png'
import LinkedIn from '../assets/linkedin-icon.png'
import Twitter from '../assets/twitter-icon.png'

const { BrightsmithDb } = Db
const { ImageUtils, StyleUtils } = Utils

interface Model extends Db.ModelEntry {
  literature: any[]
}

interface Props extends Types.BaseUIProps {
  attributes?: Types.GenericObject
  classes: Types.Classes
  configCode?: string
  configuration: Types.Configuration
  countryCode: string
  dealer?: any
  emailAddress?: string
  env: Types.Env
  history?: any
  model: Model
  modelName: String
  price: Number
  properties?: Types.GenericObject
}

const name = 'ThankYou'

const ThankYou = ({ Frame, Button, Image, Text, Input, DealerInfo, Share, styles }: Types.Components) => {
  return class extends React.Component<Props> {
    hiddenInputRef: any

    constructor(props: Props) {
      super(props)

      this.buildAnother = this.buildAnother.bind(this)
      this.buildUrl = this.buildUrl.bind(this)
      this.copyToClipboard = this.copyToClipboard.bind(this)
      this.openPDF = this.openPDF.bind(this)
      this.sendEmail = this.sendEmail.bind(this)
      this.hiddenInputRef = createRef()  
    }

    buildAnother() {
      const { history } = this.props
      history.push('/build')
    }

    buildUrl() {
      const { configCode } = this.props
      return `${window.location.origin}/build/${configCode}`
    }

    copyToClipboard() {
      this.hiddenInputRef.current?.select()
      document.execCommand('copy')
      toast.success('Copied to clipboard!')
    }

    openPDF() {
      const {
        attributes, configuration, configCode, countryCode, model, price, properties
      } = this.props
  
      window.open(`/print/${configCode}`, '_blank')
    }

    sendEmail() {
      const { configCode, dealer, emailAddress } = this.props
      Api.email.POST({ configCode, dealer, email: emailAddress })
        .then(resp => toast.success('Email sent!'))
        .catch(err => {
          console.log(err)
          toast.error('Oops! Something went wrong.')
        })
        // .finally()
    }

    render() {
      const { classes, configCode, dealer, env, modelName } = this.props
      const disclaimer = `
        By agreeing, I understand that I am providing express consent for StarEV affiliates 
        to contact me about this request or similar topics. I know that I can opt out from future
        commuication at any time.
      `
      const buildUrl = this.buildUrl()

      return (
        <Frame className={['ThankYou__container', classes?.container]} style={styles?.container}>
          <Frame className={['ThankYou__inner', classes?.inner]} style={styles?.inner}>
            <Frame className={['ThankYou__top', classes?.top]} style={styles?.top}>
              <Text
                className={['ThankYou__title', classes?.title]}
                style={styles?.title}>
                Thank you for building your custom<br/>
                {modelName}
              </Text>
              <Text className={['ThankYou__dealer', classes?.dealer]} style={styles?.dealer}>
                Your dealer will be in contact with you shortly
              </Text>
              {dealer && <DealerInfo dealer={dealer} />}
            </Frame>
            <Frame className={['ThankYou__share-wrapper', classes?.shareWrapper]} style={styles?.shareWrapper}>
              <Text className={['ThankYou__build-summary', classes?.configuration]} style={styles?.configuration}>Configuration code:</Text>
              <Text className={['ThankYou__code', classes?.code]} style={styles?.code}>{configCode}</Text>
              <Text className={['ThankYou__build-summary', classes?.buildSummary]} style={styles?.buildSummary}>Build Summary and Spec sheet:</Text>
              <Frame className={['ThankYou__buttons', classes?.buttons]} style={styles?.buttons}>
                <Button className={['ThankYou__button', classes?.button]} style={styles?.button} onClick={this.openPDF}>
                  Print
                </Button>
                <Button className={['ThankYou__button', classes?.button]} style={styles?.button} onClick={this.sendEmail}>
                  Email
                </Button>
                <Button className={['ThankYou__button', classes?.button]} style={styles?.button} onClick={this.copyToClipboard}>
                  Link
                </Button>
                <Input className={['ThankYou__hidden-input', classes?.hiddenInput]} ref={this.hiddenInputRef} value={buildUrl} />
              </Frame>
              <Text className={['ThankYou__build-summary', classes?.buildSummary]} style={styles?.buildSummary}>Share your build:</Text>
              <Share
                Facebook={<Image className={['ThankYou__icon', classes?.icon]} src={ImageUtils.getAssetUrl(env, Facebook)} style={styles?.icon} />}
                Instagram={<Image className={['ThankYou__icon', classes?.icon]} src={ImageUtils.getAssetUrl(env, Instagram)} style={styles?.icon} />}
                LinkedIn={<Image className={['ThankYou__icon', classes?.icon]} src={ImageUtils.getAssetUrl(env, LinkedIn)} style={styles?.icon} />}
                Twitter={<Image className={['ThankYou__icon', classes?.icon]} src={ImageUtils.getAssetUrl(env, Twitter)} style={styles?.icon} />} />
              <Text className={['ThankYou__disclaimer', classes?.disclaimer]} style={styles?.disclaimer}>
                {disclaimer}
              </Text>
              <Text className={['ThankYou__terms', classes?.terms]} style={styles?.terms}>
                View Terms and Privacy Policy
              </Text>
              <Button
                className={['ThankYou__build-another', classes?.buildAnother]}
                onClick={this.buildAnother}
                style={styles?.buildAnother}>
                Build another vehicle
              </Button>
            </Frame>
          </Frame>
        </Frame>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { api, app, leads } = state
  const { configuration, configCode } = api
  const { countryCode } = app

  const db = new BrightsmithDb(api)
  const model = db.getCurrentModel()
  const attributes = Object.values(db.getAttributesFromConfiguration(configuration))
  const properties = Object.values(db.getPropertiesFromConfiguration(configuration))
  const totalPrice = db.calculateTotalPrice(configuration, model, countryCode)

  return {
    attributes,
    configCode,
    countryCode,
    emailAddress: leads?.email,
    model,
    modelName: model.name,
    price: totalPrice,
    properties
  }
}

const style = theme => ({
  buildAnother: {
    background: '#000',
    borderWidth: 0,
    color: '#fff',
    cursor: 'pointer',
    flex: 1,
    fontSize: 21,
    fontWeight: 'bold',
    maxHeight: 56,
    minHeight: 56,
    textTransform: 'uppercase'
  },
  buildSummary: {
    color: '#000',
    fontSize: 16,
    fontWeight: 500
  },
  button: {
    background: '#fff',
    boxSizing: 'border-box',
    ...StyleUtils.border('2px solid #000'),
    color: '#000',
    cursor: 'pointer',
    fontWeight: 'bold',
    height: 42,
    marginBottom: '15px !important',
    marginRight: 15,
    marginTop: 15,
    padding: 0,
    paddingTop: 4,
    textTransform: 'uppercase',
    width: 90,
    '&:hover': {
      transition: 'background-color 350ms',
      background: '#e5e5e5 !important',
      color: '#000',
    }
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row'
  },
  code: {
    color: '#000',
    fontSize: 25,
    fontWeight: 300,
    marginBottom: '35px !important',
    marginTop: 10
  },
  configuration: {
    color: '#000',
    fontSize: 18,
    fontWeight: 500
  },
  container: {
    boxSizing: 'border-box',
    color: '#4B4B4B',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Source Sans, sans-serif',
    fontSize: 16,
    overflow: 'scroll',
    width: '100%'
  },
  dealer: {
    marginBottom: '15px !important'
  },
  disclaimer: {
    fontSize: 10,
    lineHeight: 1.25,
    marginBottom: '10px !important'
  },
  hiddenInput: {
    border: 0,
    height: 0,
    padding: 0,
    width: 0,
    zIndex: -1
  },
  icon: {
    height: 32,
    marginRight: 12,
    width: 32
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: 15
  },
  shareWrapper: {
    alignSelf: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  terms: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: '20px !important',
    textDecoration: 'underline',
    textTransform: 'uppercase',
  },
  title: {
    borderBottomWidth: 2,
    borderStyle: 'solid',
    color: '#252525',
    fontFamily: 'Source Sans, sans-serif',
    fontSize: 26,
    fontWeight: 300,
    lineHeight: 1.5,
    marginBottom: '15px !important',
    marginTop: 5,
    paddingBottom: 15,
    textAlign: 'left',
  },
  top: {
    display: 'flex',
    flexDirection: 'column'
  }
})

export { ThankYou, mapStateToProps, name, style, withRouter }
