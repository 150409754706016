import React from 'react'
import { withRouter } from 'react-router-dom'
import { Types } from 'brightsmith-core'
import { Actions } from 'brightsmith-redux'

interface Props extends Types.BaseUIProps {
  classes: Types.Classes
  closeModal: Function
  countryCode: string
  data?: any
  history: any
}

const name = 'VehicleSelectModal'

const VehicleSelectModal = ({ Frame, Button, Image, Text, Link, styles }: Types.Components) => {
  return class extends React.Component<Props> {
    constructor(props: Props) {
      super(props)

      this.navigateToConfigurator = this.navigateToConfigurator.bind(this)
    }

    navigateToConfigurator(id) {
      const { closeModal, history } = this.props
      history.push(`/build/${id}`)
      closeModal()
    }

    render() {
      const { classes, countryCode, data = {} } = this.props
      const { baseSpecifications = {}, configurable, description, displayInModal = [], id, name, price = {} } = data
      const localPrice = price[countryCode]

      return (
        <Frame className={['VehicleSelectModal__container', classes?.container]}>
          <Frame className={['VehicleSelectModal__half', classes?.half]}>
            <Text className={['VehicleSelectModal__title', classes?.title]}>
              {name}
            </Text>
            <Text className={['VehicleSelectModal__title', classes?.title]}>
              From ${localPrice && localPrice.toLocaleString()}
            </Text>
            <Text className={['VehicleSelectModal__description', classes?.desc]}>
              {description}
            </Text>
            {displayInModal.map(key => {
              const splt = key.split('.')
              const category = baseSpecifications[splt[0]] || {}
              const spec = category[splt[1]]

              return (
                <Text className={['VehicleSelectModal__spec', classes?.spec]}>{spec}</Text>
              )
            })}
          </Frame>
          <Frame className={['VehicleSelectModal__half', classes?.half]}>
            <Image />
            {configurable ? (
              <Button onClick={() => this.navigateToConfigurator(id)}>Build</Button>
            ) : (
              <Button>More Info</Button>
            )}
          </Frame>
        </Frame>
      )
    }
  }
}

const mapDispatchToProps = {
  closeModal: Actions.Ui.closeModal
}

const mapStateToProps = (state, ownProps) => {
  return {
    countryCode: state.app.countryCode,
    data: state.ui.modalData,
  }
}

const style = theme => ({
  container: {
    display: 'flex'
  },
  half: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  }
})

export { VehicleSelectModal, mapDispatchToProps, mapStateToProps, name, style, withRouter }
