import React from 'react'
import ReactDOM from 'react-dom'
import { AnalyticsApi } from 'brightsmith-analytics'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

AnalyticsApi.init(process.env)

let target
const classTarget = process.env.BRIGHTSMITH_RENDER_CLASS_TARGET
const targetId = process.env.BRIGHTSMITH_RENDER_TARGET || ''
try {
  if (classTarget) {
    ReactDOM.render(
      <App children />,
      document.getElementsByClassName(classTarget)[0]
    )
  } else {
    target = document.getElementById(targetId)
    ReactDOM.render(<App children />, target)
  }
} catch {
  console.error(`Error rendering app in target ${targetId}`)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
