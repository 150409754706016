import React from 'react'
import { withRouter } from 'react-router-dom'
import { Api, Types, Db } from 'brightsmith-core'
import { Utils } from 'brightsmith-ui'
import { Actions } from 'brightsmith-redux'
import { ReduxActions } from 'brightsmith-core/dist/types'
import { toast } from 'react-toastify'

const { StyleUtils } = Utils

interface Literature {
  title: string
  url: string
}

interface Model extends Db.ModelEntry {
  literature: Literature[]
}

interface Props extends Types.BaseUIProps {
  actions: ReduxActions
  attributes?: Types.GenericObject
  classes: Types.Classes
  configCode: string
  configuration: Types.Configuration
  countryCode: string
  env: Types.Env
  history: any
  literature: Literature[]
  mobile: boolean
  openModal: Function
  price: Number
  properties?: Types.GenericObject
  model: Model
  setConfigCode: Function
}

interface State { 
  disableDownload: boolean
}

const name = 'TitleBarControls'
const { BrightsmithDb } = Db

const TitleBarControls = (components: Types.Components) => {
  const { Frame, Button, Text, styles } = components

  return class extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props)

      this.state = {
        disableDownload: false
      }

      this.brochure = this.brochure.bind(this)
      this.download = this.download.bind(this)
      this.estimate = this.estimate.bind(this)
      this.getPDF = this.getPDF.bind(this)
      this.load = this.load.bind(this)
      this.locator = this.locator.bind(this)
      this.print = this.print.bind(this)
      this.save = this.save.bind(this)
    }

    brochure() {
      const { openModal } = this.props
      openModal('download-brochure')
    }

    estimate() {
      const { openModal } = this.props
      openModal('estimate-payment')
    }

    getPDF(mode: 'print' | 'download') {
      const { attributes, configCode, configuration, countryCode, history, model, price, properties, setConfigCode } = this.props
      const { disableDownload } = this.state
      
      if (!configCode) {
        if (disableDownload) {
          return
        }

        toast.success('Saving...', { autoClose: 5000, hideProgressBar: true })
        this.setState({ disableDownload: true }, () => {
          Api.configuration.POST(configuration, model, { attributes, countryCode, price, properties })
            .then(resp => {
              const configCode = resp?.data
              setConfigCode(configCode)
              history.push(`/print/${configCode}${mode === 'download' ? '?download=1' : ''}`)
              // window.open(`/print/${configCode}${mode === 'download' ? '?download=1' : ''}`, '_blank')
            })
        })
      } else {
        history.push(`/print/${configCode}${mode === 'download' ? '?download=1' : ''}`)
      }
    }

    load() {
      const { openModal } = this.props
      openModal('load-build')
    }

    locator() {
      window.location.href = 'https://www.starev.com/dealer-locator/'
    }

    download() {
      this.getPDF('download')
    }

    print() {
      this.getPDF('print')
    }

    save() {
      const { openModal } = this.props
      openModal('save-build')
    }

    render() {
      const { classes, mobile } = this.props

      return (
        <Frame className={['TitleBarControls__container', classes.container, {[classes.mobileContainer]: mobile}]}>
          {mobile ? (
            <Frame className={['TitleBarControls__mobile-options', classes.mobileOptions]}>            
              <Text className={['TitleBarControls__mobile', classes.mobile]} onClick={this.load}>Load build code</Text>
              <Text className={['TitleBarControls__mobile', classes.mobile]} onClick={this.save}>Save build code</Text>
              <Text className={['TitleBarControls__mobile', classes.mobile]} onClick={this.print}>Print build</Text>
              <Text className={['TitleBarControls__mobile', classes.mobile]} onClick={this.estimate}>Estimate monthly payment</Text>
              <Text className={['TitleBarControls__mobile', classes.mobile]} onClick={this.locator}>Dealer locator</Text>
              <Text className={['TitleBarControls__mobile', classes.mobile]} onClick={this.brochure}>Dowload brochure</Text>
            </Frame>
          ) : (
            <Frame className={['TitleBarControls__buttons', classes.buttons]}>
              <Button className={['TitleBarControls__button', classes.button]} style={styles.button} onClick={this.save}>Save</Button>
              <Button className={['TitleBarControls__button', classes.button]} style={styles.button} onClick={this.load}>Load</Button>
              <Button className={['TitleBarControls__button', classes.button]} style={styles.button} onClick={this.print}>Print</Button>
              <Button className={['TitleBarControls__button', classes.button]} style={styles.button} onClick={this.download}>Download</Button>
            </Frame>
          )}
        </Frame>
      )
    }
  }
}

const mapDispatchToProps = {
  openModal: Actions.Ui.openModal,
  setConfigCode: Actions.Configuration.setConfigCode
}

const mapStateToProps = (state, ownProps) => {
  const { api, app } = state
  const { configuration, configCode } = api
  const { countryCode, env } = app

  const db = new BrightsmithDb(api)
  const model = db.getCurrentModel()
  const attributes = Object.values(db.getAttributesFromConfiguration(configuration))
  const properties = Object.values(db.getPropertiesFromConfiguration(configuration))
  const price = db.calculateTotalPrice(configuration, model, countryCode)

  return {
    attributes,
    configCode,
    configuration,
    countryCode,
    env,
    model,
    price,
    properties,
  }
}

const style = theme => ({
  buttons: {
    display: 'flex',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    '& *:hover': {
      cursor: 'pointer'
    }
  },
  button: {
    background: 'none',
    border: 0,
    color: theme?.colors?.darkBlue,
    cursor: 'pointer',
    fontSize: 15,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: '#fff !important',
      color: '#000'
    },
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      display: 'none'
    },
    ...StyleUtils.padding('5px 10px'),
  },
  container: {
    alignSelf: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: 16,
    paddingTop: 16,
    width: 400,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      display: 'none'
    }
  },
  icon: {
    height: 'auto',
    width: 40,
  },
  mobileContainer: {
    alignSelf: 'flex-start',
    display: 'none',
    width: '100%',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      display: 'block',
    }
  },
  mobileOptions: {
    background: '#fff',
    boxSizing: 'border-box',
    flexDirection: 'column',
    ...StyleUtils.margin('0 auto'),
    padding: 30,
    paddingTop: 60,
    width: '100%',
  },
  mobile: {
    color: '#1A3249',
    cursor: 'pointer',
    fontSize: 19,
    fontWeight: 'bold',
    lineHeight: 2
  },
  tooltip: {
    bottom: -5,
    background: '#fff',
    boxShadow: '3px 1px 4px 0px rgb(0 0 0 / 50%)',
    fontSize: 10,
    fontWeight: 'bold',
    left: -14,
    padding: 3,
    position: 'absolute',
    textAlign: 'center',
    textTransform: 'uppercase',
    width: 60
  },
  translucent: {
    opacity: .2
  }
})

export { TitleBarControls, mapDispatchToProps, mapStateToProps, name, style, withRouter }
