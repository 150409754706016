import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'

// Common translations
import deCommon from './locales/de/common.json'
import enCommon from './locales/en/common.json'
import esCommon from './locales/es/common.json'
import frCommon from './locales/fr/common.json'
import itCommon from './locales/it/common.json'
import ptCommon from './locales/pt/common.json'
// Onward JSON
import deOnward from './locales/de/onward.json'
import frOnward from './locales/fr/onward.json'
import esOnward from './locales/es/onward.json'
import itOnward from './locales/it/onward.json'
import ptOnward from './locales/pt/onward.json'

const resources = {
  de: {
    translation: Object.assign({}, deCommon, deOnward),
  },
  en: {
    translation: enCommon,
  },
  es: {
    translation: Object.assign({}, esCommon, esOnward),
  },
  fr: {
    translation: Object.assign({}, frCommon, frOnward),
  },
  it: {
    translation: Object.assign({}, itCommon, itOnward),
  },
  pt: {
    translation: Object.assign({}, ptCommon, ptOnward),
  },
}

i18n
  .use(reactI18nextModule)
  .init({
    resources,
    fallbackLng: 'en',
    detection: {
      order: ['querystring', 'navigator', 'path', 'subdomain'],
      lookupQuerystring: 'lng'
    },
    interpolation: {
      escapeValue: false
    },
    nsSeparator: '::',
    saveMissingTo: 'all'
  })

export default i18n
