import * as Build from './shared/views/Build'
import * as Configurator from './shared/views/Configurator'
import * as Print from './shared/views/Print'
import { theme } from './components'
import W from './Web'

const WebBuild = W.init(Build, theme)
const WebConfigurator = W.init(Configurator, theme)
const WebPrint = W.init(Print, theme)

export { WebBuild, WebConfigurator, WebPrint }
