import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { WebBuild, WebConfigurator, WebPrint } from './views'

export default () => {
  const env = process.env
  return (
    <BrowserRouter>
      <div className="App__container">
        <Switch>
          <Redirect exact from="/" to="/build" />
          <Route
            exact
            path="/build"
            render={props => <WebBuild env={env} {...props} />}
          />
          <Route
            path="/build/:modelId"
            render={props => <WebConfigurator env={env} {...props} />}
          />
          <Route
            exact
            path="/print"
            render={props => <WebPrint env={env} {...props} />}
          />
          <Route
            path="/print/:configCode"
            render={props => <WebPrint env={env} {...props} />}
          />
        </Switch>
      </div>
    </BrowserRouter>
  )
}
