import { Utils } from 'brightsmith-ui'

const { StyleUtils } = Utils

const primary = '#695337'
const secondary = '#4B4B4B'

export const overrideTheme = theme => {
  theme.colors.primary = primary
  theme.colors.secondary = secondary
  theme.colors.darkBlue = '#1a3249'
  theme.base.fontSize = 16
  theme.base.fontFamily = 'myriad-pro'

  theme.defaults.Check.borderRadius = '0%'
  theme.defaults.Radio.scale = '1'
  theme.defaults.Radio.selectedColor = 'transparent'

  theme.select.placeholder = {
    textTransform: 'initial'
  }

  theme.Attribute.title = {
    color: '#000',
    fontSize: 16,
    fontWeight: 700,
    marginBottom: '8px !important',
    marginLeft: 0,
    marginTop: 0,
    textTransform: 'uppercase',
  }

  theme.Body.container = {
    zIndex: 9,
    '&.Body__fullScreen': {
      zIndex: 10,
      overflow: 'hidden'
    }
  }
  theme.Body.fixed = {
    minHeight: 540
  }
  theme.Body.maxWidth = {
    maxWidth: 1200
  }

  theme.Category.container = {
    ...StyleUtils.borderBottom('1px solid #000'),
    marginBottom: 10,
    marginLeft: 30,
    marginRight: 30,
    marginTop: 25,
    paddingBottom: 10
  }
  theme.Category.inner = {
    marginBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  }
  theme.Category.title = {
    color: theme?.colors?.darkBlue,
    fontSize: 24,
    fontWeight: 400,
    textTransform: 'initial'
  }

  theme.CategoryHeader.container = {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 0,
    zIndex: 2,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      boxSizing: 'border-box',
      left: 0,
      right: 0,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 0,
      position: 'absolute',
      top: 148
    }
  }
  theme.CategoryHeader.select = {
    borderRadius: 0,
    borderWidth: 0,
    ...StyleUtils.borderBottom('1px solid #000'),
    paddingLeft: 0,
    paddingRight: 0,
  }
  theme.CategoryHeader.selectWrapper = {
    fontSize: 24,
    fontWeight: 300,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      fontSize: 21
    }
  }

  theme.ColorPicker.container = {
    margin: 0,
    marginLeft: -4,
    marginRight: -2,
    marginTop: -2
  }

  theme.ColorSquare.container = {
    height: 38,
    marginLeft: 4,
    marginRight: 4,
    width: 38,
  }
  theme.ColorSquare.gradient = {
    display: 'none'
  }
  theme.ColorSquare.selected = {
    borderColor: '#707070',
    borderWidth: 2,
    boxShadow: 'none',
    margin: 2,
    marginLeft: 3,
    marginRight: 3,
    height: 40,
    width: 40
  }
  theme.ColorSquare.tooltip = {
    display: 'none'
  }

  theme.ConflictModal.thumbnailWrapper = {
    marginLeft: 10
  }
  theme.ConflictModal.subtitle = {
    marginBottom: 10
  }
  theme.ConflictModal.title = {
    marginBottom: 10,
    marginTop: 75
  }
  
  theme.FeatureSelector.categories = {
    paddingBottom: 0
  }
  theme.FeatureSelector.container = {
    boxSizing: 'border-box',
    maxWidth: '100%',
    position: 'relative',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      maxWidth: '100%',
      width: '100%',
    }
  }
  theme.FeatureSelector.inner = {
    overflowX: 'none',
    overflowY: 'scroll',
    paddingTop: 10,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      padding: 0,
      paddingTop: 205
    }
  }

  theme.InfoButton.infoButton = {
    backgroundColor: 'transparent',
    color: '#3399CC',
    fontSize: 14,
    fontStyle: 'normal',
    marginLeft: 5,
    marginTop: 0,
    textAlign: 'left',
    textIndent: 0,
    width: '30px'
  }

  theme.LeadsForm.check = {
    marginLeft: 0,
    marginRight: 0
  }
  theme.LeadsForm.container = {
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      paddingTop: 65
    }
  }
  theme.LeadsForm.fields = {
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      paddingBottom: 0
    }
  }
  theme.LeadsForm.inner = {
    marginTop: 10,
    paddingLeft: 0,
    paddingRight: 0,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      paddingBottom: 90
    }
  }
  theme.LeadsForm.input = {
    border: 0,
    background: '#E3E3E3',
    boxSizing: 'border-box',
    color: '#000 !important',
    height: 40
  }
  theme.LeadsForm.inputWrapper = {
    marginLeft: '1%',
    marginRight: '1%'
  }
  theme.LeadsForm.instructions = {
    fontSize: 16,
    lineHeight: 1.25,
    marginLeft: '1%',
    marginRight: '1%'
  }
  theme.LeadsForm.required = {
    display: 'none'
  }
  theme.LeadsForm.select = {
    background: '#E3E3E3',
    border: 0,
    borderRadius: 0
  }
  theme.LeadsForm.title = {
    borderBottomWidth: 2,
    fontSize: 24,
    fontWeight: 300,
    marginBottom: '15px !important',
    marginLeft: '1%',
    marginRight: '1%',
    textAlign: 'left',
    textTransform: 'initial'
  }

  theme.Loading.indicator = {
    borderTopColor: '#000'
  }
  
  theme.Modal.addToBuild = {
    display: 'none'
  }
  theme.Modal.container = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    maxWidth: 800,
    paddingTop: 18,
    paddingBottom: 18,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      fontSize: 19
    }
  }
  theme.Modal.content = {
    color: '#000 !important',
    fontFamily: 'myriad-pro, Roboto, sans-serif !important',
    flex: 1,
    [`@media (min-width: ${theme?.breakpoints?.mobile}px)`]: {
      fontSize: 19,
      minWidth: 320
    }
  }
  theme.Modal.description = {
    color: '#4B4B4B',
    fontSize: 24,
    fontWeight: 100,
    lineHeight: 1.4,
    marginBottom: 23,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      fontSize: 19
    }
  }
  theme.Modal.featureImageWrapper = {
    flex: 2,
    marginRight: 21
  }
  theme.Modal.featureImage = {
    height: 'auto',
    maxHeight: 'none',
    width: '100%'
  }
  theme.Modal.infoContainer = {
    flexDirection: 'row',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      flexDirection: 'column'
  },
  }
  theme.Modal.infoTitle = {
    fontSize: 46
  }
  theme.Modal.input = {
    padding: '18px 12px',
    fontWeight: 900
  }
  theme.Modal.price = {
    fontSize: 32,
    fontWeight: 'bold'
  }
  theme.Modal.title = {
    color: '#000',
    fontSize: 40,
    fontWeight: 'bold',
    lineHeight: '40px',
    marginTop: 56
  }

  theme.Price.price = {
    color: theme.colors.darkBlue,
    fontWeight: 700
  }

  theme.Property.bodyText = {
    color: '#000',
    fontFamily: 'myriad-pro, Roboto, sans-serif',
    fontSize: 14,
    lineHeight: 1,
    marginLeft: 5
  }
  theme.Property.container = {
    background: '#fff',
    ...StyleUtils.border('1px solid #e3e3e3'),
  }
  theme.Property.head = {
    minWidth: 0,
    visibility: 'hidden',
    width: 0
  }
  theme.Property.inner = {
    paddingLeft: 0,
    paddingRight: 0
  }
  theme.Property.price = {
    ...StyleUtils.margin('0px 12px'),
  }
  theme.Property.selected = {
    background: '#f8f7f7',
    ...StyleUtils.border('2px solid #1a3249'),
    marginBottom: 9,
    marginLeft: -1,
    marginRight: -1,
    marginTop: -1
  }
  theme.Property.tail = {
    flexDirection: 'column',
    height: 75,
    justifyContent: 'center'
  }
  theme.Property.thumbnail = {
    height: 75
  }
  theme.Property.thumbnailWrapper = {
    maxHeight: 75,
    minHeight: 75,
    maxWidth: 75,
    minWidth: 75
  }

  theme.Render.container = {
    display: 'block',
    marginBottom: 5,
    padding: 0,
    [`@media (max-width: ${theme.breakpoints.mobile}px)`]: {
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 10
    }
  }
  theme.Render.drawArea = {
    display: 'block',
    ...StyleUtils.margin('0 auto'),
    height: 'initial',
    maxHeight: '100%',
    width: 'auto'
  }
  theme.Render.drawAreaContainer = {
    flexDirection: 'column',
    [`@media (max-width: ${theme.breakpoints.mobile}px)`]: {
      height: 200,
      maxHeight: 200
    }
  }
  theme.Render.fullScreen = {
    top: 118
  }
  theme.Render.height = 1800 / 2
  theme.Render.textFullScreen = {
    bottom: 0,
    [`@media (max-width: ${theme.breakpoints.mobile}px)`]: {
      bottom: 5,
    },
  }
  theme.Render.width = 2145 / 2

  theme.Share.container = {
    marginBottom: 65,
    marginTop: 15,
    justifyContent: 'flex-start',
    padding: 0
  }
 
  theme.Slideshow.close = {
    height: 25,
    width: 25
  }
  theme.Slideshow.container = {
    boxSizing: 'border-box',
    display: 'flex',
    flex: 5,
    flexDirection: 'column',
    order: 2,
    paddingBottom: 10,
    paddingRight: 15,
    paddingTop: 15,
    zIndex: 3,
    position: 'relative',
    [`@media (max-width: ${theme.breakpoints.mobile}px)`]: {
      background: '#fff',
      paddingRight: 0,
      paddingTop: 5,
      // position: 'fixed',
      top: 125,
      zIndex: 9
    }
  },
  theme.Slideshow.slide = {
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      ...StyleUtils.margin('0 auto'),
      maxWidth: '50wh'
    },
  }
  theme.Slideshow.slides = {
    zIndex: 2,
    [`@media (max-width: ${theme.breakpoints.mobile}px)`]: {
      minHeight: 150,
      position: 'relative'
    }
  }
  theme.Slideshow.thumb = {
    minWidth: 182,
    width: 182,
    [`@media (max-width: ${theme.breakpoints.mobile}px)`]: {
      minHeight: '3vh',
      minWidth: '11vh'
    }
  }
  theme.Slideshow.thumbs = {
    height: 150,
    zIndex: 1,
    [`@media (max-width: ${theme.breakpoints.mobile}px)`]: {
      height: 80,
      maxWidth: '100%',
      margin: 'auto'
    }
  }

  theme.Summary.container = {
    background: 'rgba(26, 50, 73, 0.9)',
    display: 'flex',
    flex: 1
  }
  theme.Summary.header = {
    borderColor: 'transparent',
    borderWidth: 0,
    color: '#fff',
    fontSize: 28,
    fontWeight: 300
  }
  theme.Summary.inner = {
    boxSizing: 'border-box',
    padding: 20,
    paddingTop: 44
  }

  theme.Tabs.borderBottom = {
    backgroundColor: '#1a3249',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      maxWidth: 60,
    }
  }
  theme.Tabs.tab = {
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      marginLeft: 25,
      maxWidth: 60,
      padding: 0
    }
  }
  theme.Tabs.text = {
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      fontSize: 14,
      padding: 0
    }
  }

  theme.TitleBar.breadcrumb = {
    fontSize: 28
  }
  theme.TitleBar.breadcrumbsContainer = {
    [`@media (max-width: ${theme.breakpoints.mobile}px)`]: {
      left: 16,
      position: 'absolute',
      top: 20
    }
  }
  theme.TitleBar.breadcrumbText = {
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      fontSize: 14,
      paddingRight: 10,
      paddingTop: 2
    },
  }
  theme.TitleBar.container = {
    backgroundColor: 'white',
    [`@media (max-width: ${theme.breakpoints.mobile}px)`]: {
      border: 0,
      // position: 'fixed',
      top: 0
    }
  }
  theme.TitleBar.inner = {
    position: 'relative',
    [`@media (max-width: ${theme.breakpoints.mobile}px)`]: {
      justifyContent: 'center'
    }
  }
  theme.TitleBar.title = {
    ...StyleUtils.borderLeft('1px solid #e3e3e3'),
    color: theme?.colors?.darkBlue,
    fontFamily: 'myriad-pro-semiextended, sans-serif',
    fontSize: 48,
    fontWeight: 600,
    letterSpacing: -0.96,
    marginLeft: 30,
    paddingLeft: 20,
    position: 'relative',
    textTransform: 'uppercase',
    top: -4,
    [`@media (max-width: ${theme.breakpoints.mobile}px)`]: {
      ...StyleUtils.borderLeft('none'),
      marginLeft: 0,
      paddingLeft: 0,
      fontSize: 24,
      top: 0
    }
  }
  theme.TitleBar.titleWrapper = {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    paddingLeft: 16,
    paddingBottom: 16,
    paddingTop: 16,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      flex: 'unset',
      justifyContent: 'center',
      paddingBottom: 4,
      paddingLeft: 0
    },
  }

  theme.Toggle.toggle = {
    background: '#E3E3E3',
    color: '#A3A2A2',
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      fontSize: 12
    },
  }
  theme.Toggle.toggleActive = {
    color: '#fff'
  }

  theme.VehicleThumbnail.build = {
    border: 'none',
    background: 'none'
  }
  theme.VehicleThumbnail.thumbnail = {
    height: 'auto',
    width: '100%'
  }
  theme.VehicleThumbnail.title = {
    fontWeight: 'bold',
    marginBottom: 0,
    fontSize: 30,
    letterSpacing: -0.6
  }
}