import * as components from 'brightsmith-ui'
import { theme } from 'brightsmith-ui'
import * as custom from './shared/components'
import { overrideTheme } from './shared/theme'
import W from './Web'

overrideTheme(theme)

const ColorSquare = W.init(components.ColorSquare, theme)
const ColorPicker = W.init(components.ColorPicker, theme)
const InfoButton = W.init(custom.InfoButton, theme)
const LSV = W.init(custom.LSV, theme)
const Property = W.init(components.Property, theme)
const Attribute = W.init(components.Attribute, theme)
const Body = W.init(components.Body, theme)
const BaseSpecifications = W.init(components.BaseSpecifications, theme)
const Category = W.init(components.Category, theme)
const CategoryHeader = W.init(components.CategoryHeader, theme)
const FeatureSelector = W.init(custom.FeatureSelector, theme)
const Grid = W.init(components.Grid, theme)
const Header = W.init(components.Header, theme)
// const LeadsForm = W.init(components.LeadsForm, theme)
const Loading = W.init(components.Loading, theme)
const ConflictModal = W.init(components.ConflictModal, theme)
const Modal = W.init(components.Modal, theme)
const ModalBackground = W.init(components.ModalBackground, theme)
const Price = W.init(components.Price, theme)
const RenderControls = W.init(components.RenderControls, theme)
const ScrollListener = W.init(components.ScrollListener, theme)
const Share = W.init(components.Share, theme)
const Slideshow = W.init(components.Slideshow, theme)
const Tabs = W.init(components.Tabs, theme)
const TitleBar = W.init(components.TitleBar, theme)
const Toggle = W.init(components.Toggle, theme)

const ConnectButtons = W.init(custom.ConnectButtons, theme)
const SubmitButtons = W.init(custom.SubmitButtons, theme)
const Summary = W.init(custom.Summary, theme)
const VehicleThumbnail = W.init(custom.VehicleThumbnail, theme)
const VehicleRow = W.init(custom.VehicleRow, theme)
const VehicleSelectModal = W.init(custom.VehicleSelectModal, theme)
const LoadBuildModal = W.init(custom.LoadBuildModal, theme)
const SaveBuildModal = W.init(custom.SaveBuildModal, theme)
const DownloadBrochureModal = W.init(custom.DownloadBrochureModal, theme)
const EstimatePaymentModal = W.init(custom.EstimatePaymentModal, theme)
const TitleBarControls = W.init(custom.TitleBarControls, theme)
const SeatingDropdown = W.init(custom.SeatingDropdown, theme)
const ModelDropdown = W.init(custom.ModelDropdown, theme)
const BuildCodeButton = W.init(custom.BuildCodeButton, theme)
const AboutSirius = W.init(custom.AboutSirius, theme)
const DealerInfo = W.init(custom.DealerInfo, theme)
const ThankYou = W.init(custom.ThankYou, theme)
const MobileActionsModal = W.init(custom.MobileActionsModal, theme)
const SwitchModal = W.init(custom.SwitchModal, theme)
const Footer = W.init(custom.Footer, theme)
const StandardFeatures = W.init(custom.StandardFeatures, theme)
const LeadsForm = W.init(custom.LeadsForm, theme)
const Render = W.init(custom.Render, theme)

if (process.env.NODE_ENV === 'development') {
  W.debug({ ...components })
}

export {
  Attribute,
  BaseSpecifications,
  Body,
  Category,
  CategoryHeader,
  ColorPicker,
  ColorSquare,
  FeatureSelector,
  Footer,
  Grid,
  Header,
  InfoButton,
  LeadsForm,
  Loading,
  ConflictModal,
  Modal,
  ModalBackground,
  Price,
  Property,
  Render,
  RenderControls,
  ScrollListener,
  Share,
  Slideshow,
  Tabs,
  TitleBar,
  Toggle,
  theme,
  // custom
  ConnectButtons,
  DealerInfo,
  SubmitButtons,
  Summary,
  ThankYou,
  VehicleRow,
  VehicleSelectModal,
  LoadBuildModal,
  SaveBuildModal,
  DownloadBrochureModal,
  EstimatePaymentModal,
  TitleBarControls,
  SeatingDropdown,
  ModelDropdown,
  BuildCodeButton,
  AboutSirius,
  MobileActionsModal,
  VehicleThumbnail,
  SwitchModal,
  LSV,
  W,
  StandardFeatures,
}
