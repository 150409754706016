import React from 'react'
import { withRouter } from 'react-router-dom'
import { Types } from 'brightsmith-core'
import { Utils } from 'brightsmith-ui'
import { Actions } from 'brightsmith-redux'
import BuildCodeIcon from '../assets/build-code-icon.svg'

const { ImageUtils } = Utils

interface Props extends Types.BaseUIProps {
  classes: Types.Classes
  env: Types.Env
  openModal: Function
}

const name = 'BuildCodeButton'

const BuildCodeButton = ({ Frame, Text, Input, LoadBuildModal, ModalBackground, Modal, Image }: Types.Components) => {
  return class extends React.Component<Props> {
    render() {
      const { classes, env, openModal } = this.props
      return (
        <Frame
          className={['BuildCodeButton__container', classes?.container]}
          onClick={() => openModal('load-build')}>
          <Image className={['BuildCodeButton__icon', classes?.icon]} src={ImageUtils.getAssetUrl(env, BuildCodeIcon)} />
          Enter your build code
        </Frame>
      )
    }
  }
}

const mapDispatchToProps = {
  openModal: Actions.Ui.openModal
}

const mapStateToProps = (state, ownProps) => {
  const { app } = state
  const { env } = app
  return { env } 
}

const style = theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'myriad-pro, sans-serif',
    fontWeight: '300',
    marginBottom: 30,
    marginTop: 30,
    '&:hover': {
      cursor: 'pointer'
    },
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      borderBottom: '2px solid #E3E3E3',
      marginTop: 0,
      paddingBottom: 12,
    }
  },
  icon: {
    width: theme.base.fontSize * 2,
    marginRight: 12
  }
})

export { BuildCodeButton, mapDispatchToProps, mapStateToProps, name, style, withRouter }
