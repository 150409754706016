import React from 'react'
import { Classes, Components, BaseUIProps, GenericObject, FormFields, Configuration } from 'brightsmith-core/dist/types'
import { Actions } from 'brightsmith-redux'
import { Helpers, Utils } from 'brightsmith-ui'
import { Db } from 'brightsmith-core'

const { BrightsmithDb } = Db
const { LeadsFormHelper } = Helpers
const { StyleUtils } = Utils

interface Opts {
  required?: {
    after?: boolean
    before?: boolean
  }
}

interface Props extends BaseUIProps {
  classes: Classes
  configuration: Configuration
  countryCode?: string
  fields?: FormFields
  instructions?: string | React.ReactNode
  leadData?: any
  model: Db.ModelEntry
  onCancel?: Function
  onSubmit?: (leadsData: GenericObject, model: Db.ModelEntry, configuration: Configuration) => void
  opts?: Opts
  saveLead: Function
  smallPrint?: string | React.ReactNode
  SubmitButtons?: any  // Brightsmith Component
  submitText?: string  // Note: if SubmitButtons has a value, this is ignored
  title?: string
  setLeadIsValid: Function
  leadIsValid: boolean
}

interface State {
  errors: GenericObject
  isSubmitting: boolean
  showErrorsOnInput: boolean
}

const name = 'LeadsForm'

const LeadsForm = ({
  Button,
  Check,
  Frame,
  Text,
  Input,
  Select,
  styles,
}: Components) => {
  return class extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props)
      
      this.state = {
        errors: {},
        isSubmitting: false,
        showErrorsOnInput: false
      }

      this.onChange = this.onChange.bind(this)
      this.renderFields = this.renderFields.bind(this)
      this.renderSubmit = this.renderSubmit.bind(this)
      this.submitForm = this.submitForm.bind(this)
    }

    onChange(name, value) {
      const { saveLead } = this.props
      saveLead(name, value)
      process.nextTick(() => this.validateFields(this.state.showErrorsOnInput))
    }

    renderFields(fields: FormFields, classes: Classes, opts?: Opts) {
      const { countryCode, leadData } = this.props
      const { errors } = this.state
      const getOptions = options => {
        if (typeof options === 'function') {
          const { country } = leadData
          if (country || countryCode) {
            return options(country || countryCode)
          }
          return []
        }
        return options
      }

      return fields.map((field: any) => {  // TODO resolve FormFields typing error
        const { key, label, placeholder } = field
        const error = errors[key]
        const value = leadData[key] || ''

        const options = getOptions(field.options)

        const Required = () => (
          <Text className={['LeadsForm__Required', classes?.required]} style={styles?.required}>
            {field.required && '*'}
          </Text>
        )

        const sizeClass = field.half ? classes?.half : classes?.fullWidth
        const sizeStyle = field.half ? styles?.half : styles?.fullWidth

        return (
          <Frame className={['LeadsForm__input-wrapper', classes?.inputWrapper, sizeClass]} style={{ ...styles?.inputWrapper, ...sizeStyle }} key={key}>
            {opts?.required?.before === true && <Required />}
            {(() => {
              switch (field.type) {
                case 'select':
                  return (
                    <Select
                      className={['LeadsForm__Select', classes?.select, { [classes?.error]: error }]}
                      name={key}
                      onChange={val => this.onChange(key, val)}
                      options={options}
                      placeholder={placeholder}
                      style={styles?.select}
                      value={value}
                    />
                  )
                case 'check':
                  return (
                    <Check
                      className={['LeadsForm__Check', classes?.check, { [classes?.error]: error }]}
                      onChange={e => this.onChange(key, !value)}
                      isSelected={!!value}
                      name={key}
                      label={label}
                      style={styles?.check}
                    />
                  )
                case 'text':
                  return (
                    <Input
                      className={['LeadsForm__Input', classes?.input, { [classes?.error]: error }]}
                      name={key}
                      onChange={e => this.onChange(key, e.target.value)}
                      placeholder={placeholder}
                      style={styles?.input}
                      value={value}
                    />
                  )
                case 'phone':
                  return (
                    <Input
                      className={['LeadsForm__Phone', classes?.input, { [classes?.error]: error }]}
                      inputMode="numeric"
                      name={key}
                      onChange={e => this.onChange(key, e.target.value)}
                      pattern="[0-9]*"
                      placeholder={placeholder}
                      style={styles?.input}
                      value={value}
                    />
                  )
                case 'html':
                  return <Frame className={['LeadsForm__html', classes?.flex, { [classes?.error]: error }]}>{field.content}</Frame>
              }
            })()}
            {opts?.required?.before !== true && opts?.required?.after !== false && <Required />}
          </Frame>
        )
      })
    }

    renderInstructions(instructions: string | React.ReactNode, classes: Classes) {
      if (typeof instructions === 'string') {
        return (
          <Text className={['LeadsForm__instructions', classes?.instructions]}
            style={styles?.instructions}>
            {instructions}
          </Text>
        )
      }
      return instructions
    }

    renderSmallPrint(smallPrint: string | React.ReactNode, classes: Classes) {
      if (typeof smallPrint === 'string') {
        return (
          <Text
            className={['LeadsForm__small-print', classes?.smallPrint]}
            style={styles?.smallPrint}
          />
        )
      }
      return smallPrint
    }

    renderSubmit() {
      const { classes, onCancel, SubmitButtons, submitText } = this.props
      const { errors, isSubmitting } = this.state
      const hasErrors = Object.keys(errors).length > 0
      if (SubmitButtons) {
        return (
          <SubmitButtons
            onCancel={onCancel}
            onSubmit={this.submitForm}
            submitDisabled={hasErrors}
            submitting={isSubmitting} />
        )
      }
      return (
        <Button
          className={['LeadsForm__submit', classes?.submit]}
          style={styles?.submit}
          onClick={this.submitForm}>
          {submitText || 'Submit'}
        </Button>
      )
    }

    submitForm(e) {
      const { leadData } = this.props
      const { configuration, model, onSubmit } = this.props

      const showErrors = true
      const errors = this.validateFields(showErrors)

      const hasErrors = (Object.values(errors).indexOf(true) > -1)
      if (onSubmit && !hasErrors) {
        this.setState({ isSubmitting: true })
        onSubmit(leadData, model, configuration)
      } else {
        this.setState({ showErrorsOnInput: true })
      }
    }

    validateFields(showErrors: boolean) {
      const { countryCode, leadData, setLeadIsValid, leadIsValid } = this.props
      const formFields = LeadsFormHelper.getFormFields(this.props)
      
      let allFieldsAreValid = true
      let errors = {}

      formFields.forEach(field => {
        const { key } = field
        const value = leadData[key]
        errors[key] = false

        if (field.required && [null, undefined, '', false].indexOf(value) >= 0) {
          errors[key] = true
        }
        if (field.validate) {
          const { validate } = field
          const isValid = validate(leadData, field.required, { countryCode })
          errors[key] = !isValid
        }
        if (errors[key] && field.required) {
          allFieldsAreValid = false
        }
      })

      if (leadIsValid !== allFieldsAreValid) {
        setLeadIsValid(allFieldsAreValid)
      }

      if (showErrors) {
        this.setState({ errors })
      }

       return errors
    }

    render() {
      const { after, before, classes, instructions, opts, title, smallPrint, setLeadIsValid } = this.props
      const formFields = LeadsFormHelper.getFormFields(this.props)
      return (
        <Frame
          className={['LeadsForm__container', classes?.container]}
          style={{overflow: 'scroll', ...styles?.container}}>
          {before}
          <Frame className={['LeadsForm__inner', classes?.inner]} style={styles?.inner}>
            <Text
              className={['LeadsForm__title', classes?.title]}
              style={styles?.title}>
              {title || 'Your Personalized Summary'}
            </Text>
            {this.renderInstructions(instructions, classes)}
            <Frame
              className={['LeadsForm__fields', classes?.fields]}
              style={styles?.fields}>
              {this.renderFields(formFields, classes, opts)}
              {this.renderSmallPrint(smallPrint, classes)}
              {this.renderSubmit()}
            </Frame>
          </Frame>
          {after}
        </Frame>
      )
    }
  }
}

const mapDispatchToProps = {
  saveLead: Actions.saveLead,
  setLeadIsValid: Actions.setLeadIsValid
}

const mapStateToProps = (state, ownProps) => {
  const { api, app, leads } = state
  const { configuration } = api
  const { countryCode } = app

  const db = new BrightsmithDb(api)
  const model = db.getCurrentModel()
  const { leadIsValid } = leads

  return {
    configuration,
    countryCode,
    leadData: leads,
    leadIsValid,
    model,
  }
}

const style = theme => ({
  check: {
    fontSize: 14,
    fontFamily: theme?.base?.fontFamily || 'sans-serif',
    marginBottom: 10,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
    ...theme?.LeadsForm?.check
  },
  container: {
    backgroundColor: '#f7f7f7',
    boxSizing: 'border-box',
    color: '#000',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    ...theme?.LeadsForm?.container
  },
  error: {
    ...StyleUtils.border('1px solid #f00'),
    boxShadow: '0 0 4px 1px #f00',
    outline: 'none',
    ...theme?.LeadsForm?.error
  },
  half: {
    width: '48%'
  },
  inner: {
    padding: 15
  },
  fullWidth: {
    width: '100%'
  },
  title: {
    borderBottomWidth: 1,
    borderStyle: 'solid',
    fontWeight: '500',
    marginTop: 5,
    paddingBottom: 15,
    textAlign: 'center',
    textTransform: 'uppercase',
    ...theme?.LeadsForm?.title
  },
  fields: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 15,
    paddingLeft: 10,
    paddingRight: 10,
    ...theme?.LeadsForm?.inner
  },
  flex: {
    flex: 1
  },
  input: {
    borderColor: theme?.colors?.secondary,
    borderRadius: 2,
    borderWidth: 1,
    flex: 1,
    fontSize: 14,
    fontFamily: theme?.base?.fontFamily || 'sans-serif',
    fontWeight: '300',
    marginBottom: 0,
    maxWidth: '100%',
    padding: 7,
    paddingLeft: 9,
    paddingRight: 9,
    '&::placeholder': {
      color: theme?.colors?.secondary,
      textTransform: 'uppercase',
    },
    ...theme?.LeadsForm?.input
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    ...StyleUtils.margin('0 20px 10px'),
    ...theme?.LeadsForm?.inputWrapper
  },
  instructions: {
    ...theme?.LeadsForm?.instructions
  },
  required: {
    color: 'red',
    height: 10,
    paddingTop: 10,
    textAlign: 'center',
    width: 15,
    ...theme?.LeadsForm?.required
  },
  select: {
    borderColor: theme?.colors?.secondary,
    borderRadius: 2,
    borderWidth: 1,
    flex: 1,
    fontSize: 14,
    fontFamily: theme?.base?.fontFamily || 'sans-serif',
    fontWeight: '300',
    ...theme?.LeadsForm?.select
  },
  submit: {
    backgroundColor: theme?.colors?.secondary,
    borderRadius: 5,
    borderWidth: 0,
    color: '#fff',
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: '500',
    marginTop: 15,
    padding: 12,
    textAlign: 'center',
    textTransform: 'uppercase',
    ...theme?.LeadsForm?.submit
  },
})

export { LeadsForm, name, mapDispatchToProps, mapStateToProps, style }
