import React, { createRef } from 'react'
import { Api, Types, Db } from 'brightsmith-core'
import { Actions } from 'brightsmith-redux'
import { Utils } from 'brightsmith-ui'

const { BrightsmithDb } = Db
const { StyleUtils } = Utils

interface Props extends Types.BaseUIProps {
  attributes?: Types.GenericObject
  buildCode?: string
  classes: Types.Classes
  closeModal: Function
  configCode?: string
  configuration?: Types.Configuration
  countryCode: string
  model?: Db.ModelEntry
  price: Number
  properties?: Types.GenericObject
  setConfigCode: Function
}

interface State {
  completed: Boolean
  emailAddress: string
  requested: Boolean
  sendingEmail: Boolean
  showEmailField: Boolean
}

const name = 'SaveBuildModal'

const SaveBuildModal = ({ Frame, Text, Input, Button, Loading, style }: Types.Components) => {
  return class extends React.PureComponent<Props, State> {
    inputRef: any

    constructor(props) {
      super(props)

      this.state = {
        completed: false,
        emailAddress: '',
        requested: false,
        sendingEmail: false,
        showEmailField: false
      }

      this.inputRef = createRef()
      this.copyToClipboard = this.copyToClipboard.bind(this)
      this.saveConfiguration = this.saveConfiguration.bind(this)
      this.sendEmail = this.sendEmail.bind(this)
      this.setEmailAddress = this.setEmailAddress.bind(this)
      this.showEmailField = this.showEmailField.bind(this)
    }

    componentDidMount() {
      const { configCode } = this.props
      if (!configCode) {
        this.saveConfiguration()
      }
    }

    shouldComponentUpdate(nextProps, nextState) {
      if (nextState.requested !== this.state.requested) {
        return false
      }
      return true
    }

    copyToClipboard() {
      this.inputRef.current?.select()
      document.execCommand('copy')
    }

    saveConfiguration() {
      const {
        attributes, configuration, countryCode, model, price, properties, setConfigCode
      } = this.props
      this.setState({
        requested: true
      })
      Api.configuration.POST(configuration, model, { attributes, countryCode, price, properties })
        .then(resp => {
          const configCode = resp?.data
          setConfigCode(configCode)
          this.setState({
            completed: true
          })        
        })
    }

    sendEmail() {
      const { configCode } = this.props
      const { emailAddress } = this.state
      this.setState({ sendingEmail: true })
      Api.email.POST({ configCode, email: emailAddress })
        .then(resp => console.log(resp))
        .catch(err => console.log(err))
        .finally(() => this.setState({ sendingEmail: false }))
    }

    setEmailAddress(e) {
      this.setState({ emailAddress: e.target.value });
    }

    showEmailField() {
      const { configCode } = this.props
      if (configCode) {
        const { showEmailField } = this.state
        this.setState({ showEmailField: !showEmailField })
      }
    }

    render() {
      const { classes, configCode } = this.props
      const { emailAddress, sendingEmail, showEmailField } = this.state
      return (
        <Frame className={['SaveBuildModal__container', classes?.container]}>
          <Text className={['SaveBuildModal__title', classes?.title]}>
            Here is your custom build code
          </Text>
          <Text className={['SaveBuildModal__description', classes?.description]}>
            Use the code below to save or share your custom build
          </Text>
          {!configCode ?
            <Frame className={['SaveBuildModal__loading-wrapper', classes?.loadingWrapper]}>
              <Loading />
            </Frame>
            :
            <Input
              disabled
              className={['SaveBuildModal__input', classes?.input]}
              ref={this.inputRef}
              value={configCode}
              style={style?.input} />
          }
          <Frame className={['SaveBuildModal__buttons', classes?.buttons]}>
            <Text onClick={this.copyToClipboard}>Copy to clipboard</Text>
            <Text>|</Text>
            <Text onClick={this.showEmailField}>Email</Text>
          </Frame>
          {showEmailField && (
            <>
              <Input
                className={['SaveBuildModal__input', classes?.input]}
                placeholder="Enter your email address"
                onChange={this.setEmailAddress}
                style={style?.input}
                value={emailAddress} />
              <Button
                className={['SaveBuildModal__button', classes?.button]}
                onClick={this.sendEmail}
                style={style?.button}>
                  {sendingEmail ? <Loading backgroundColor="#FFF" color="#AAA" height={40} width={40} /> : 'Send'}
              </Button>
            </>
          )}
        </Frame>
      )
    }
  }
}

const mapDispatchToProps = {
  closeModal: Actions.Ui.closeModal,
  setConfigCode: Actions.Configuration.setConfigCode
}

const mapStateToProps = (state, ownProps) => {
  const { api, app } = state
  const { configCode, configuration } = api
  const { countryCode } = app
  const db = new BrightsmithDb(api)
  
  const model = db.getCurrentModel()
  const attributes = Object.values(db.getAttributesFromConfiguration(configuration))
  const properties = Object.values(db.getPropertiesFromConfiguration(configuration))
  const totalPrice = db.calculateTotalPrice(configuration, model, countryCode)

  return {
    attributes,
    configCode,
    configuration,
    countryCode,
    model,
    price: totalPrice,
    properties
  }
}

const style = theme => ({
  ...theme?.Modal,
  button: {
    background: '#000',
    borderWidth: 0,
    color: '#fff',
    cursor: 'pointer',
    flex: 1,
    fontSize: 26,
    fontWeight: 'bold',
    minHeight: 60,
    position: 'relative',
    textTransform: 'uppercase',
    width: 250,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      fontSize: 19
    }
  },
  buttons: {
    cursor: 'pointer',
    display: 'flex',
    gap: '10px',
    fontWeight: 900,
    marginBottom: 10
  },
  input: {
    ...StyleUtils.border('1px solid #E3E3E3'),
    boxSizing: 'border-box',
    fontSize: 20,
    fontWeight: 'bold',
    height: 84,
    marginBottom: 8,
    padding: 30,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      fontSize: 19
    }
  },
  loadingWrapper: {
    height: 75,
    position: 'relative',
    width: 75
  }
})

export { SaveBuildModal, mapDispatchToProps, mapStateToProps, name, style }
