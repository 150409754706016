import React, { ReactNode } from 'react'
import { Db } from 'brightsmith-core'
import { Classes, Components, Configuration, Env, PropertyMap } from 'brightsmith-core/dist/types'
import { Actions } from 'brightsmith-redux'
import { faExpand } from '@fortawesome/free-solid-svg-icons'
import { Helpers, Utils } from 'brightsmith-ui'
import IconTimes from '../assets/close-icon-black.svg'

const { BrightsmithDb } = Db
const { RenderHelper } = Helpers
const { ImageUtils, StyleUtils } = Utils

interface Props {
  activeView: number
  after?: ReactNode
  before?: ReactNode
  classes?: Classes
  configuration: Configuration
  controls: ReactNode
  env: Env
  fullScreen: boolean
  hasLoaded: boolean
  Loading?: ReactNode
  model: Db.ModelEntry
  setFullScreen: Function
  modelId?: string
  opts?: {
    fullScreen?: boolean
    magnify: boolean
    magnifyWindowSize?: number
    title?: boolean
  }
  properties: PropertyMap
  type?: 'canvas' | 'layer'
}

interface State {
  isLoading: boolean
}

const name = 'Render'

const Render = ({ Frame, Canvas, Icon, Image, Text, styles, theme }: Components) => {
  return class extends React.PureComponent<Props, State> {
    constructor(props: Props) {
      super(props)

      this.state = {
        isLoading: true,
      }

      this.handleClickFullScreen = this.handleClickFullScreen.bind(this)
      this.renderViewArea = this.renderViewArea.bind(this)
      this.setIsLoading = this.setIsLoading.bind(this)
    }

    renderViewArea(props: Props) {
      const { activeView, classes, env, fullScreen, model, opts, properties } = props
      const urls = RenderHelper.getUrls(env, activeView, model, properties)
      
      const height = model?.display?.height || theme?.Render?.height
      const width = model?.display?.width || theme?.Render?.width
      const scale = model?.display?.scale || theme?.Render?.scale || 1

      switch (props.type) {
        case 'canvas':
        default: 
          return (
            <Canvas
              {...opts}
              className={[classes?.drawArea]}
              containerCls={[classes?.canvasContainer]}
              magnifierCls={[classes?.magnifier]}
              height={height * scale}
              isFullScreen={fullScreen}
              setIsLoading={this.setIsLoading}
              urls={urls}
              width={width * scale}
              style={styles?.drawArea}
            />
          )
      }
    }

    handleClickFullScreen() {
      const { fullScreen, setFullScreen } = this.props
      setFullScreen(!fullScreen)
    }

    setIsLoading(isLoading: boolean) {
      this.setState({
        isLoading
      })
    }

    render() {
      const {
        after,
        before,
        classes,
        controls,
        env,
        fullScreen,
        hasLoaded,
        Loading,
        model,
        opts,
      } = this.props
      const { isLoading } = this.state
      const showTitle = opts?.title && model?.name

      return (
        <Frame className={['Render__container', classes?.container, { [classes?.fullScreen || '']: fullScreen }]}>
          {before}
          <Frame className={['Render__inner', classes?.inner]} style={styles?.inner}>
            {fullScreen && (
              <Image className={['Render__fullScreenCloseButton', classes?.fullScreenCloseButton]} src={ImageUtils.getAssetUrl(env, IconTimes)} onClick={this.handleClickFullScreen} />
            )}
            {showTitle && (
              <Text className={['Render__title', classes?.title]} style={styles?.title}>
                {model?.name}
              </Text>
            )}
            <Frame className={['Render__drawAreaContainer', classes?.drawAreaContainer, {[classes?.drawAreaContainerMax || '']: fullScreen}]} styles={styles?.drawAreaContainer} onClick={() => fullScreen && this.handleClickFullScreen()}>
              {hasLoaded && this.renderViewArea(this.props)}
              {isLoading && Loading}
            </Frame>
            {controls}
            {opts?.fullScreen && !fullScreen && (
              <Frame className={['Render__fullScreen', classes?.textFullScreen]} onClick={this.handleClickFullScreen} style={styles?.textFullScreen}>
                <Icon
                  className={['Render__fullScreenIcon', classes?.fullScreenButton]}
                  icon={faExpand}>
                </Icon>
                <Text className={['Render__fullScreenButtonText', classes?.fullScreenButtonText]}>
                  Full Screen View
                </Text>
              </Frame>
            )}
          </Frame>
          {after}
        </Frame>
      )
    }
  }
}

const mapDispatchToProps = {
  setFullScreen: Actions.Ui.setFullScreen
}

const mapStateToProps = (state, ownProps) => {
  const { api, ui } = state
  
  const { configuration, hasLoaded } = api
  const { activeView, fullScreen } = ui

  const db = new BrightsmithDb(api)
  const model = db.getCurrentModel()
  const properties = db.getPropertiesFromConfiguration(configuration)

  return {
    activeView,
    configuration,
    fullScreen,
    hasLoaded,
    model,
    properties,
  }
}

const style = theme => ({
  container: {
    background: theme?.base?.backgroundColor,
    display: 'flex',
    flex: 5,
    flexDirection: 'column',
    order: 2,
    padding: 15,
    position: 'relative',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      zIndex: 10
    },
    ...theme?.Render?.container,
  },
  canvasContainer: {
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      display: 'flex',
      alignItems: 'center'
    },
    ...theme?.Render?.canvasContainer
  },
  closeButton: {
    height: 40,
    position: 'absolute',
    right: 0,
    paddingRight: 36,
    paddingTop: 36,
    width: 40,
    ...theme?.Render?.closeButton
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
    ...theme?.Render?.controls
  },
  drawArea: {
    maxWidth: '100%',
    width: theme.Render?.width,
    ...theme?.Render?.drawArea
  },
  drawAreaContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    maxHeight: '100%',
    position: 'relative',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      height: 150,
      maxHeight: 150
    },
    ...theme?.Render?.drawAreaContainer
  },
  drawAreaContainerMax: {
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      height: '100%',
      maxHeight: '100%'
    },
    ...theme?.Render?.drawAreaContainerMax
  },
  fullScreen: {
    backgroundColor: theme?.base?.backgroundColor || '#fff',
    '@web': {
      '-webkit-overflow-scrolling': 'touch',
      bottom: 0,
      left: 0,
      position: 'fixed',
      right: 0,
      top: 0,
      zIndex: 100,
      ...theme?.Render?.fullScreen
    },
    ...theme?.Render?.fullScreen
  },
  fullScreenButton: {
    fontSize: 16,
    ...StyleUtils.margin('0 6px'),
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      // display: 'none',
    },
    ...theme?.Render?.fullScreenButton
  },
  fullScreenButtonText: {
    ...StyleUtils.margin('0 6px'),
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      display: 'none'
    },
    ...theme?.Render?.fullScreenButtonText
  },
  fullScreenCloseButton: {
    height: 40,
    position: 'absolute',
    right: 0,
    paddingRight: 40,
    paddingTop: 40,
    top: 0,
    width: 40,
    zIndex: 1,
    '&:hover': {
      cursor: 'pointer'
    },
    ...theme?.Render?.fullScreenButtonText
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: '100%',
    maxWidth: theme?.Body?.maxWidth,
    width: '100%',
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      justifyContent: 'center'
    },
    ...theme?.Render?.inner
  },
  magnifier: {
    borderRadius: 75,
    boxShadow: '0px 3px 6px #00000029',
    display: 'none',
    left: 0,
    position: 'absolute',
    pointerEvents: 'none',
    top: 0,
    ...theme?.Render?.magnifier
  },
  textFullScreen: {
    bottom: 20,
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: '500',
    left: 15,
    position: 'absolute',
    textDecoration: 'underline',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    ...theme?.Render?.textFullScreen
  },
  title: {
    alignSelf: 'flex-start',
    fontFamily: 'Roboto',
    fontSize: 36,
    fontWeight: '300',
    ...theme?.Render?.title,
    '@mobile': {
      fontSize: 26,
    },
    ...theme?.Render?.fullScreenButtonText
  }
})

export { Render, mapStateToProps, mapDispatchToProps, name, style }
