import React from 'react'
import { Types } from 'brightsmith-core'
import * as Actions from '../store/actions'

interface Props extends Types.BaseUIProps {
  actions: Types.GenericObject,
  classes: Types.Classes,
  setSeatingFilter: Function
}

interface State {
  open: boolean
}

const name = 'SeatingDropdown'

const SeatingDropdown = ({ Frame, Button, Select, Text, styles }: Types.Components) => {
  return class extends React.Component<Props, State> {
    selectCategory(id) {
      const { setSeatingFilter } = this.props
      setSeatingFilter(id)
    }

    render() {
      const { classes } = this.props
      const options: Types.SelectOption[] = [
        {
          label: 'Seating 2+',
          value: '2'
        },
        {
          label: 'Seating 4+',
          value: '4'
        },
        {
          label: 'Seating 6+',
          value: '6'
        }
      ]
      return (
        <Select
          className={['Seating-Dropdown__Select', classes?.select]}
          onChange={(selected: Types.SelectOption) => this.selectCategory(selected.value)}
          options={options}
          placeholder="Select Seating"
          style={styles?.select} />
      )
    }
  }
}

const mapDispatchToProps = {
  setSeatingFilter: Actions.setSeatingFilter
}

const mapStateToProps = (state, ownProps) => {

}

const style = theme => ({
})

export { SeatingDropdown, mapDispatchToProps, mapStateToProps, name, style }
