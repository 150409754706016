import { instance as RulesEngine, MongoDocument } from 'brightsmith-rules'
import { Types } from 'brightsmith-core'
import { GenericObject } from 'brightsmith-core/dist/types'
import { Actions } from 'brightsmith-redux'
import store from './store'

export default class RulesApi {
  static init(data) {
    // Override engine getters to match model
    RulesEngine.getIsDefault = (doc: MongoDocument) => {
      return doc.standard
    }
    RulesEngine.getDefaultPropertyId = (attribute: MongoDocument) => {
      return attribute.defaultPropertyId
    }

    RulesEngine.init(data)
    RulesApi.setConfiguration(data)
  }

  static loadInitialConfiguration(data: Types.GenericObject = {}) {
    const state = store.getState()
    const { api } = state
    const response = RulesEngine.getBaseConfiguration()
    const { after, overrides } = response

    if (api.stash) {
      RulesApi.reconcile(after, api.stash, data)
    }

    console.log('RulesApi::initial', response)
    store.dispatch(Actions.Configuration.set(after, overrides))
  }

  static reconcile(configuration: Types.Configuration, stash: Types.Configuration, data: Types.GenericObject) {
    console.log('RulesApi::reconcile', stash)
    const { attributes, properties } = data
    if (attributes?.allIds && properties?.allIds) {
      Object.keys(stash).forEach((attributeId: Types.Id) => {
        if (attributes.allIds.includes(attributeId)) {
          const propertyId = stash[attributeId]
          if (properties.allIds.includes(propertyId)) {
            if (attributeId !== 'suspension-0') {  // StarEV exception
              configuration[attributeId] = propertyId
            }
          }
        }
      })
    }
  }

  static select(attributeId: Types.Id, propertyId?: Types.Id) {
    const state = store.getState()
    const { api } = state
    const { configuration } = api

    const selection = {[attributeId]: propertyId}
    const response = RulesEngine.run(configuration, selection, true)
    console.log('RulesApi::select', response)
    const { after, changes, overrides } = response
    if (changes.length > 0) {
      // TODO respond with a modal if necessary from changes object
      store.dispatch(Actions.Ui.openModal('conflict', { changes, selection }))
      console.warn('CHANGES', changes)
      return
    }
    
    store.dispatch(Actions.Configuration.set(after, overrides))
  }

  static setConfiguration(data: GenericObject) {
    if (!data.configuration) {
      RulesApi.loadInitialConfiguration(data)
      return
    }
    store.dispatch(Actions.Configuration.set(data.configuration, []))
  }
}