import React, { createRef, ReactNode } from 'react'
import smoothscroll from 'smoothscroll-polyfill'
import { Db } from 'brightsmith-core'
import { BaseUIProps, Classes, Components, Id } from 'brightsmith-core/dist/types'
import { Util } from 'brightsmith-core'
import { Actions } from 'brightsmith-redux'
import { Utils } from 'brightsmith-ui'

const { DataUtils, StyleUtils } = Utils

if (!Util.isReactNative()) {
  smoothscroll.polyfill()
}

const { BrightsmithDb } = Db

interface Props extends BaseUIProps {
  Category?: ReactNode
  categoryIds?: Id[]
  CategoryHeader?: React.ReactNode
  classes: Classes
  currentCategoryId: Id
  currentExteriorColor: Db.PropertyEntry
  currentSeatColor: Db.PropertyEntry
  LeadsForm?: React.ReactNode
  left?: Boolean
  setCurrentCategoryId: Function
  shouldScrollToCategory?: Boolean
}

const name = 'FeatureSelector'

const FeatureSelector = (components: Components) => {
  let { Frame, Category, Text, styles } = components

  return class extends React.PureComponent<Props> {
    categoryRefs: { string?: React.Ref<any> } = {}
    constructor(props: Props) {
      super(props)

      if (props.Category) {
        Category = props.Category
      }

      this.renderByCategory = this.renderByCategory.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
      const { currentCategoryId, shouldScrollToCategory } = this.props
      if (prevProps?.currentCategoryId !== currentCategoryId && shouldScrollToCategory) {
        const ref = this.categoryRefs[currentCategoryId]
        const { parentElement } = ref.current
        if (parentElement) {
          ref.current.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }

    renderByCategory(categoryIds) {
      const { classes, currentExteriorColor, currentSeatColor } = this.props
      return categoryIds
        .map(categoryId => {
          this.categoryRefs[categoryId] = createRef()
          const components =[
            <Category
              key={categoryId}
              categoryId={categoryId}
              forwardedRef={this.categoryRefs[categoryId]}
            />
          ]
          if (categoryId === 'exterior-color' || categoryId === 'exterior-color-0') {
            components.push(<Text className={['FeatureSelector__selected-color', classes?.selectedColor]}>
                {currentExteriorColor.name}
            </Text>)
          } else if (categoryId === 'seating-color-0') {
            components.push(<Text className={['FeatureSelector__selected-color', classes?.selectedColor]}>
                {currentSeatColor.name}
            </Text>)
          }
          return components
      })
    }

    render() {
      const {
        after,
        before,
        CategoryHeader,
        categoryIds,
        classes,
        currentCategoryId,
        LeadsForm,
        left,
        setCurrentCategoryId
      } = this.props

      return (
        <Frame
          className={[
            'Feature-Selector__container',
            classes?.container,
            { [classes?.floatLeft]: left === true },
          ]}
          style={styles?.container}>
          {before}
          {CategoryHeader}
          <Frame
            className={['Feature-Selector__inner', classes?.inner]}
            onScroll={(e) => {
              const { scrollTop } = e.target
              Object.keys(this.categoryRefs).reverse().some(categoryId => {
                const ref = this.categoryRefs[categoryId]
                const { offsetTop } = ref.current
                if (scrollTop > offsetTop - 115) {  // TODO need to figure out math here
                  if (currentCategoryId !== categoryId) {
                    setCurrentCategoryId(categoryId, false)
                  }
                  return true
                }
                return false
              })
            }}
            style={styles?.inner}>
            <Frame
              className={['Feature-Selector__categories', classes?.categories, {}]}
              style={styles?.categories}>
              {categoryIds && this.renderByCategory(categoryIds)}
            </Frame>
            {LeadsForm}
          </Frame>
          {after}
        </Frame>
      )
    }
  }
}

const mapDispatchToProps = {
  setCurrentCategoryId: Actions.Ui.setCurrentCategory
}

const mapStateToProps = (state, ownProps) => {
  const { api, ui } = state
  const db = new BrightsmithDb(api)
  const configuration = db.getConfiguration()
  const currentExteriorColor = db.getProperty(configuration['standard'] || configuration['limited-edition'] ||
    configuration['standard-0'] || configuration['limited-edition-0'])
  const currentSeatColor = db.getProperty(configuration['standard-seats-0'] || configuration['premium-seats-0'])

  const categories: Db.CategoryEntry[] = DataUtils.sort(db.getCategories())
  const categoryIds = categories
    .filter(c => !!!c.parentCategory && c.active !== false)  // Filter out anything that is not active or has a parent category (child categories will be loaded later)
    .map(c => c.id)

  const { currentCategoryId, shouldScrollToCategory } = ui 

  return {
    categoryIds,
    currentCategoryId,
    currentExteriorColor,
    currentSeatColor,
    shouldScrollToCategory
  }
}

const style = theme => ({
  categories: {
    paddingBottom: 35,
    ...theme?.FeatureSelector?.categories
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 350,
    order: 2,
    zIndex: 2,
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      maxWidth: 'none',
      minWidth: 'none'
    },
    '@mobile': {
      maxWidth: '100%'
    },
    ...theme?.FeatureSelector?.container
  },
  floatLeft: {
    order: 1,
  },
  hideOnMobile: {
    [`@media (max-width: ${theme?.breakpoints?.mobile}px)`]: {
      display: 'none'
    }
  },
  inner: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    ...theme?.FeatureSelector?.inner
  },
  scroll: {
    display: 'flex',
    height: '100%',
    ...theme?.FeatureSelector?.scroll
  },
  selectedColor: {
    color: '#000',
    ...StyleUtils.borderBottom('1px solid #000'),
    fontSize: 14,
    ...StyleUtils.margin('0 30px'),
    paddingBottom: 15
  }
})

export { FeatureSelector, name, mapDispatchToProps, mapStateToProps, style }
